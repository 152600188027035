import React from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { premiumService } from "../../commonTypes";
import { separateThousands } from "../../common";

interface IProps {
  services: premiumService[];
  selectedServices: number[];
  selectService: (id: number, select: boolean) => void;
  orderPirce: number;
}

export default function Gifts({ services, selectedServices, selectService, orderPirce }: IProps) {
  return (
    <div id="giftsContainer" style={{ marginLeft: "32px" }}>
      {services.length > 0 ? (
        <>
          <Typography variant="h6">Dále Vám nabízíme tyto Prémiové služby</Typography>
          <br />
        </>
      ) : (
        <>
          <br />
          <br />
        </>
      )}
      {services.map((s, i) => (
        <Grid container key={i}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={s.minPrice <= orderPirce && selectedServices.includes(i)}
                  onChange={(e) => {
                    if (!s.obligatory) selectService(i, e.target.checked);
                  }}
                  color="primary"
                  disabled={s.minPrice > orderPirce}
                />
              }
              label={`${s.name}${
                s.price > 0 ? `: ${s.freeFrom === 0 ? s.price : s.freeFrom < orderPirce ? 0 : s.price} Kč` : ""
              }${s.minPrice > 0 ? ` (dostupné od ${separateThousands(s.minPrice.toString())} Kč)` : ""}${
                s.freeFrom > 0 ? ` (zdarma od ${separateThousands(s.freeFrom.toString())} Kč)` : ""
              }`}
            />
            {s.subtitle && (
              <>
                <br />
                <Typography style={{ marginLeft: "32px" }} variant="caption">
                  {s.subtitle}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
