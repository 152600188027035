import { Button, Card, CardActions, CardContent, CardHeader, Fade, Modal, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { priceList } from "../../commonTypes";
import { commonClasses } from "../../styles";

export default function ({ onCreate }: { onCreate: (res: priceList) => void }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");

  const styles = commonClasses();

  const resetState = () => {
    setShow(false);
    setName("");
  };

  const createPriceList = () => {
    if (name === "") {
      alert("Zadejte název cenníku");
      return;
    }
    let formData = new FormData();
    formData.append("name", name);
    fetch(`/api/priceLists/create.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        response.json().then((res) => {
          onCreate(res);
          resetState();
        });
      })
      .catch(console.log);
  };

  return (
    <>
      <br />
      <Button variant="contained" color="primary" className="right" onClick={() => setShow(true)}>
        Vytvořit ceník
      </Button>

      <Modal open={show} onClose={() => setShow(false)} style={{ width: "30%", left: "35%", top: "20%" }} closeAfterTransition>
        <Fade in={show}>
          <Card className={styles.fakCard}>
            <CardHeader className={styles.fakCardHeader} title="Vytvořit ceník" />
            <CardContent>
              <TextField fullWidth label="Název ceníku" value={name} onChange={(e) => setName(e.target.value)} />
            </CardContent>
            <CardActions>
              <Button variant="contained" color="secondary" onClick={resetState}>
                Zrušit
              </Button>
              <Button variant="contained" color="primary" onClick={createPriceList}>
                Vytvořit
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
