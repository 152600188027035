import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { priceTableFormat } from "../../commonTypes";

const defaultFormat: priceTableFormat = {
  sukl: true,
  basicPrice: true,
  commonPrice: true,
  suklPrice: true,
  recommendedAmmount: true,
  ammountDiscountsCount: 0,
  mixDiscounts: [],
};

export default function ({ onCreate }: { onCreate: (name: string, format: priceTableFormat) => void }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [format, setFormat] = useState<priceTableFormat>(defaultFormat);
  const resetState = () => {
    setShow(false);
    setName("");
    setFormat(defaultFormat);
  };
  const createPriceList = () => {
    if (name === "") {
      alert("Zadejte název ceníku");
      return;
    }
    onCreate(name, format);
    resetState();
  };

  return (
    <>
      <br />
      <Button variant="contained" color="primary" className="right" onClick={() => setShow(true)}>
        Vytvořit novou tabulku
      </Button>

      <Dialog open={show} onClose={() => setShow(false)}>
        <DialogTitle id="form-dialog-title">Přidat tabulku</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Název tabulky"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                margin="dense"
                label="Počet množst. slev pro 1 produkt"
                value={format.ammountDiscountsCount}
                onChange={(e) => setFormat({ ...format, ammountDiscountsCount: parseInt(e.target.value) })}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox checked={format.sukl} onChange={(e) => setFormat({ ...format, sukl: !format.sukl })} color="primary" />
            }
            label="Súkl"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={format.basicPrice}
                onChange={(e) => setFormat({ ...format, basicPrice: !format.basicPrice })}
                color="primary"
              />
            }
            label="Základní cena"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={format.commonPrice}
                onChange={(e) => setFormat({ ...format, commonPrice: !format.commonPrice })}
                color="primary"
              />
            }
            label="Běžná cena"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={format.recommendedAmmount}
                onChange={(e) => setFormat({ ...format, recommendedAmmount: !format.recommendedAmmount })}
                color="primary"
              />
            }
            label="Doporučené množství"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={format.suklPrice}
                onChange={(e) => setFormat({ ...format, suklPrice: !format.suklPrice })}
                color="primary"
              />
            }
            label="Cena dle Súkl"
          />
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={11}>
              <Typography variant="h6">Slevové kategorie MIX</Typography>
            </Grid>
            <Grid item xs={1}>
              <FontAwesomeIcon
                style={{ cursor: "pointer", color: "green" }}
                icon={faPlus}
                onClick={() => setFormat({ ...format, mixDiscounts: [...format.mixDiscounts, 0] })}
              />
            </Grid>
          </Grid>

          {format.mixDiscounts.map((d, i) => (
            <TextField
              type="number"
              label="minimální množství"
              fullWidth
              margin="dense"
              value={d}
              onChange={(e) => {
                let newArr = [...format.mixDiscounts];
                newArr[i] = parseInt(e.target.value);
                setFormat({ ...format, mixDiscounts: newArr });
              }}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={resetState} color="default">
            Zrušit
          </Button>
          <Button onClick={createPriceList} color="primary">
            Vytvořit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
