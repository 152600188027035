import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import React, { useState } from "react";

export default function ({ onCreate }: { onCreate: (gift: { name: string; minAmmount: number; price: number }) => void }) {
  const emptyInfo = { name: "", minAmmount: 0, price: 0 };
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState(emptyInfo);
  const resetState = () => {
    setShow(false);
    setInfo(emptyInfo);
  };
  const createPriceList = () => {
    if (info.name === "") {
      alert("Zadejte název dárku");
      return;
    }
    onCreate(info);
    resetState();
  };

  return (
    <>
      <br />
      <Button variant="contained" color="primary" className="right" onClick={() => setShow(true)}>
        Přidat dárek
      </Button>

      <Dialog open={show} onClose={resetState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>Přidat dárek</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            autoFocus
            label="Název dárku"
            value={info.name}
            onChange={(e) => setInfo({ ...info, name: e.target.value })}
          />
          <TextField
            fullWidth
            label="Minimální odběr"
            type="number"
            value={info.minAmmount}
            onChange={(e) => setInfo({ ...info, minAmmount: parseInt(e.target.value) })}
          />
          <TextField
            fullWidth
            label="Cena dárku"
            type="number"
            value={info.price}
            onChange={(e) => setInfo({ ...info, price: parseInt(e.target.value) })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={resetState}>Zrušit</Button>
          <Button color="primary" onClick={createPriceList}>
            Vytvořit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
