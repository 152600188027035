import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FAK } from "../../commonTypes";

export default function ({
  onCreate,
  fakNames,
  usrEmail,
}: {
  onCreate: (name: FAK) => void;
  fakNames: string[];
  usrEmail: string | undefined;
}) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const reset = () => {
    setOpen(false);
    setName("");
  };

  const createFAK = () => {
    if (fakNames.includes(name)) {
      alert("Název lékárničky musí být unikátní");
      return;
    }
    let formData = new FormData();
    formData.append("name", name);
    if (usrEmail !== undefined) formData.append("email", usrEmail);
    fetch(`/api/fak/create.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res: FAK) => {
            onCreate(res);
            reset();
          });
        } else {
          alert("Při vytváření lékárničky došlo k chybě");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <Fab color="primary" variant="extended" aria-label="add" onClick={() => setOpen(true)}>
        <AddIcon /> Přidat&nbsp;lékárničku
      </Fab>
      <Dialog open={open} onClose={reset} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Vytvořit lékárničku</DialogTitle>
        <DialogContent>
          <DialogContentText align="justify">
            Do lékárničky si můžete ukládat Vaše produkty spolu s jejich expirační dobou. Systém Vám automaticky hlídá expiraci
            všech produktů v lékárničce a upozorní Vás v případě, že některý z produktů brzy expiruje. Systém automaticky doplní
            počty produktů v lékárničce po každé Vaší objednávce. Produkty z objednávky budou automaticky roztřízeny do sáčků
            podle lékárniček, do kterých patří. Věříme, že Vám maximálně usnadníme práci a kontrolu.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Název lékárničky na škole"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={reset} color="default">
            Zrušit
          </Button>
          <Button
            onClick={() => {
              if (name.length === 0) {
                alert("Zadejte název");
                return;
              }
              createFAK();
              reset();
            }}
            color="primary"
          >
            Vytvořit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
