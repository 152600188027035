import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import BusinessIcon from "@material-ui/icons/Business";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { UserInformation } from "../../commonTypes";
import ChangePwdForm from "../EditProfile/ChangePwdForm";
import config from "../../Enviroment";

const defaultState: UserInformation = {
  City: "",
  CompanyId: "",
  CompanyName: "",
  DelCity: "",
  DelCompanyName: "",
  DelHouseNum: "",
  DelStreet: "",
  DelZIP: "",
  DelEmail: "",
  DelPhone: "",
  Email: "",
  HouseNum: "",
  Name: "",
  Phone: "",
  PayTax: undefined,
  Street: "",
  VAT: "",
  ZIP: "",
  Pwd: "",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface IProps {
  registartion: boolean;
  onFormSubmit: (formState: UserInformation) => void;
  submitButtonText: string;
}

export default function ({ registartion, onFormSubmit, submitButtonText }: IProps) {
  const [showPwd, setShowPwd] = useState(false);
  const [formState, setFormState] = useState<UserInformation>(defaultState);
  const classes = useStyles();

  const ZIPFormater = (str: string | boolean) => {
    str = str.toString().replace(/ /g, "");
    if (str.length > 3) str = str.substring(0, 3) + " " + str.substring(3);
    return str;
  };

  const CompIdFormater = (str: string | boolean) => {
    str = str.toString().replace(/ /g, "");
    if (str.length > 3) str = str.substring(0, 3) + " " + str.substring(3);
    if (str.length > 6) str = str.substring(0, 6) + " " + str.substring(6);
    return str;
  };

  const PhoneFormater = (str: string | boolean) => {
    str = str.toString().replace(/ /g, "");
    const pref = str.startsWith("+420") ? "+420 " : "";
    str = str.replace("+420", "");
    if (str.length > 3) str = str.substring(0, 3) + " " + str.substring(3);
    if (str.length > 7) str = str.substring(0, 7) + " " + str.substring(7);
    return pref + str;
  };

  const changeProp = (
    propName: keyof UserInformation,
    newVal: string | boolean,
    format?: (str: string | boolean) => string | boolean
  ) => {
    if (format === undefined) format = (str) => str;
    let newState = { ...formState } as UserInformation;
    newState[propName] = format(newVal) as never;
    setFormState(newState);
  };

  if (!registartion) {
    useEffect(() => {
      fetch("/api/users/get.php")
        .then((res) => res.json())
        .then((data) => {
          setFormState({ ...data, PayTax: data.PayTax === 1 });
        })
        .catch(console.log);
    }, []);
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit(formState);
      }}
    >
      <Grid container>
        <Grid item xs={12} lg={4}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>{registartion ? <LockOutlinedIcon /> : <AccountCircleIcon />}</Avatar>
              <Typography component="h1" variant="h5">
                {registartion ? <>Registrace</> : <>Osobní údaje</>}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Jméno a příjmení registrujícího se pracovníka"
                value={formState.Name}
                onChange={(e) => {
                  changeProp("Name", e.target.value);
                }}
                autoFocus
              />
              {registartion && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    value={formState.Email}
                    onChange={(e) => {
                      changeProp("Email", e.target.value);
                    }}
                  />
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="standard-adornment-password">Heslo</InputLabel>
                    <Input
                      type={showPwd ? "text" : "password"}
                      value={formState.Pwd}
                      onChange={(e) => {
                        changeProp("Pwd", e.target.value);
                      }}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={() => setShowPwd(!showPwd)}>
                            {showPwd ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                label="Telefon registrujícího se pracovníka"
                value={formState.Phone}
                onChange={(e) => {
                  changeProp("Phone", e.target.value, PhoneFormater);
                }}
              />
              {!registartion && <ChangePwdForm />}
            </div>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <BusinessIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Fakturační adresa {config.institution}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                label={`Název ${config.institution}`}
                value={formState.CompanyName}
                onChange={(e) => {
                  changeProp("CompanyName", e.target.value);
                }}
              />
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Ulice"
                    value={formState.Street}
                    onChange={(e) => {
                      changeProp("Street", e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Číslo popisné"
                    value={formState.HouseNum}
                    onChange={(e) => {
                      changeProp("HouseNum", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="PSČ"
                    value={formState.ZIP}
                    onChange={(e) => {
                      changeProp("ZIP", e.target.value, ZIPFormater);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Město"
                    value={formState.City}
                    onChange={(e) => {
                      changeProp("City", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="IČ"
                    value={formState.CompanyId}
                    onChange={(e) => {
                      changeProp("CompanyId", e.target.value, CompIdFormater);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="DIČ"
                    value={formState.VAT}
                    onChange={(e) => {
                      changeProp("VAT", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <FormControl style={{ width: "100%", marginTop: "16px", float: "left" }} component="fieldset">
                <FormLabel component="legend">Jsem plátce DPH</FormLabel>
                <RadioGroup
                  row
                  onChange={(e) => {
                    changeProp("PayTax", e.target.value === "true");
                  }}
                >
                  <FormControlLabel value={true} control={<Radio checked={formState.PayTax === true} />} label="Ano" />
                  <FormControlLabel value={false} control={<Radio checked={formState.PayTax === false} />} label="Ne" />
                </RadioGroup>
              </FormControl>
              <Button
                onClick={() => {
                  const newFormState = { ...formState };
                  newFormState.DelCompanyName = formState.CompanyName;
                  newFormState.DelStreet = formState.Street;
                  newFormState.DelHouseNum = formState.HouseNum;
                  newFormState.DelZIP = formState.ZIP;
                  newFormState.DelCity = formState.City;
                  setFormState(newFormState);
                }}
                fullWidth
                color="primary"
                className={classes.submit}
              >
                Zkopírovat do dodací adresy
              </Button>
            </div>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LocalShippingIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Dodací adresa {config.institution}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                label={`Název ${config.institution}`}
                value={formState.DelCompanyName}
                onChange={(e) => {
                  changeProp("DelCompanyName", e.target.value);
                }}
              />
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Ulice"
                    value={formState.DelStreet}
                    onChange={(e) => {
                      changeProp("DelStreet", e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Číslo popisné"
                    value={formState.DelHouseNum}
                    onChange={(e) => {
                      changeProp("DelHouseNum", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="PSČ"
                    value={formState.DelZIP}
                    onChange={(e) => {
                      changeProp("DelZIP", e.target.value, ZIPFormater);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Město"
                    value={formState.DelCity}
                    onChange={(e) => {
                      changeProp("DelCity", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <TextField
                margin="normal"
                fullWidth
                label="Telefon na pracovníka přebírajícího zásilky"
                value={formState.DelPhone}
                onChange={(e) => {
                  changeProp("DelPhone", e.target.value, PhoneFormater);
                }}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Email na pracovníka přebírajícího zásilky"
                value={formState.DelEmail}
                onChange={(e) => {
                  changeProp("DelEmail", e.target.value);
                }}
              />
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                {submitButtonText}
              </Button>
            </div>
          </Container>
        </Grid>
      </Grid>
    </form>
  );
}
