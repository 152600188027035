import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { priceList } from "../../commonTypes";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import config from "../../Enviroment";

function notifyPerEmail(email: string) {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("subject", `V Aplikaci ${config.appName} Vám byla přidělena Přehledná nabídka`);
  formData.append(
    "body",
    `
    <html>
      <body>
      <p>
        V Aplikaci <a href="https://${window.location.hostname}">${config.appName}</a> pro školy Vám byla přiřazena Přehledná nabídka produktů podle
        věku pro mateřské, základní a střední školy (každá individuální nabídka obsahuje tipy,
        rady a obvyklá upozornění od našich lékárníků vztahující se k věku pacientů).
        Přihlásit se, spravovat a dovybavovat Vaše lékárničky můžete na odkazu níže.
      </p>
        <br/>
        <a href="https://${window.location.hostname}">${config.appName}</a><br/>
        (Aplikace nepodporuje prohlížeč Internet Explorer z důvodu zastaralosti a bezpečnosti, zvolte, prosím, jiný prohlížeč, doporučujeme použít např. Google Chrome.) Aplikaci můžete najít i pomocí našich stránek Lékárny Selská. Po otevření našich stránek je v menu odkaz Aplikace Lékárničky.<br/>
        <br/>
        <p>
          Děkujeme Vám, že využíváte inovativní Aplikaci <a href="https://${window.location.hostname}">${config.appName}</a>.<br/>
          S přáním pěkného dne<br/>
          Mgr. Martin Všetečka<br/>
          Odborný zástupce rodinné Lékárny Selská (Rodinné Lékárny Magistr Martin)<br/>
          Telefon do lékárny: 545 230 805<br/>
          E-mail do lékárny: ${config.mail}<br/>
        </p>
      </body>
    </html>`
  );
  fetch("/api/mails/sendEmail.php", {
    method: "POST",
    body: formData,
  });
}

interface IProps {
  priceLists: priceList[] | undefined;
  actList: number;
  loadPriceLists: () => void;
  onPricelistChange: (newList: number) => void;
  userEmail: string;
}

export default function ({ priceLists, actList, loadPriceLists, onPricelistChange, userEmail }: IProps) {
  const [show, setShow] = useState(false);
  const [selectedList, setSelectedList] = useState(actList);
  const selectPriceList = () => {
    if (selectedList === actList) {
      resetState();
      return;
    } else {
      let formData = new FormData();
      formData.append("userEmail", userEmail);
      formData.append("listId", selectedList.toString());
      fetch(`/api/users/changePriceList.php`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            onPricelistChange(selectedList);
            notifyPerEmail(userEmail);
            setShow(false);
          } else {
            alert("Při ukládání došlo k chybě");
            resetState();
          }
        })
        .catch(console.log);
    }
  };
  const resetState = () => {
    setShow(false);
    setSelectedList(actList);
  };
  return (
    <>
      <Tooltip title="Spravovat ceníky">
        <FontAwesomeIcon
          icon={faTag}
          onClick={(e) => {
            if (priceLists === undefined) loadPriceLists();
            setShow(true);
          }}
          style={{ color: actList === -1 ? "orange" : "blue", cursor: "pointer" }}
        />
      </Tooltip>
      {priceLists && (
        <Dialog open={show} onClose={() => setShow(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Přiřadit ceník</DialogTitle>
          <DialogContent>
            <FormControl fullWidth style={{ minWidth: "360px" }}>
              <InputLabel>Ceník</InputLabel>
              <Select value={selectedList} onChange={(e) => setSelectedList(e.target.value as number)}>
                {priceLists.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShow(false)} color="default">
              Zrušit
            </Button>
            <Button
              onClick={() => {
                selectPriceList();
                setShow(false);
              }}
              color="primary"
            >
              Vybrat
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
