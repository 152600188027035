import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { FAK, FAKItem } from "../../commonTypes";
import dateformat from "dateformat";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintDownloader from "./FakPdfDownloader";
import WarningIcon from "@material-ui/icons/Warning";
import FAKExpProductRemove from "./FAKExpProductRemove";

interface IProps {
  fak: FAK;
  onUpdate: (updated: FAK) => void;
  onDelete: () => void;
  admin: boolean;
  orderProducts: (prods: { sukl: string; ammount: number }[]) => void;
}

function NewItemAdder({ addItem }: { addItem: (item: FAKItem) => void }) {
  const [newRow, setNewRow] = useState<FAKItem | undefined>(undefined);
  const emptyFAKItem: FAKItem = {
    id: -1,
    ammount: 0,
    expirationDate: new Date(),
    name: "",
    sukl: "",
  };
  return (
    <>
      <TableRow>
        {newRow === undefined ? (
          <TableCell colSpan={5}>
            <IconButton onClick={() => setNewRow(emptyFAKItem)}>
              <AddIcon />
            </IconButton>
          </TableCell>
        ) : (
          <>
            <TableCell>
              <TextField label="SÚKL" value={newRow.sukl} onChange={(e) => setNewRow({ ...newRow, sukl: e.target.value })} />
            </TableCell>
            <TableCell>
              <TextField label="Název" value={newRow.name} onChange={(e) => setNewRow({ ...newRow, name: e.target.value })} />
            </TableCell>
            <TableCell>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM-yyyy"
                  views={["year", "month"]}
                  style={{ width: "120px" }}
                  autoOk={true}
                  value={newRow.expirationDate}
                  margin="normal"
                  onChange={(e) => {
                    if (e) setNewRow({ ...newRow, expirationDate: e });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </TableCell>
            <TableCell>
              <TextField
                label="Množství"
                value={newRow.ammount}
                style={{ width: "80px" }}
                type="number"
                onChange={(e) => setNewRow({ ...newRow, ammount: parseInt(e.target.value) })}
              />
            </TableCell>
            <TableCell>
              <IconButton
                onClick={() => {
                  addItem(newRow);
                  setNewRow(undefined);
                }}
              >
                <DoneIcon />
              </IconButton>
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell>
          <WarningIcon style={{ color: "#ff9800" }} />
        </TableCell>
        <TableCell colSpan={4}>
          <Typography variant="caption" style={{ color: "#ff9800" }}>
            Při přidávání produktů, které nebyly do Vaší lékárničky dovybaveny přes naši Aplikaci, je pro správné fungování
            funkce DOOBJEDNAT PRODUKTY potřeba do pole SÚKL uvést tento kód. U nabízených produktů ho nejdete hned v prvním
            sloupci. U doplňků stravy, zdravotnického materiálu, produktů „Stejná účinná látka, levnější výrobce“ a dalších
            skupin v nabídce používáme jejich zkratku a interní číslo. Při dovybavování přes Aplikace funguje toto přiřazování
            identifikace automaticky.
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

function NameEditor({ edit, onChange, fakName }: { edit: boolean; onChange: (newName: string) => void; fakName: string }) {
  const [name, setName] = useState(fakName);

  return edit ? (
    <TextField label="Název lékárničky" value={name} onChange={(e) => setName(e.target.value)} onBlur={() => onChange(name)} />
  ) : (
    <>Lékárnička: {fakName}</>
  );
}

function DeleteFak({ onDelete }: { onDelete: () => void }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button color="secondary" onClick={() => setOpen(true)}>
        Smazat lékárnčku
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Opravdu chcete lékárničku smazat?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lékárnička bude smazána i s veškerým svým obsahem. Po smazání již není možné lékárničku obnovit.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="default">
            Zrušit
          </Button>
          <Button
            onClick={() => {
              onDelete();
              setOpen(false);
            }}
            color="secondary"
            autoFocus
          >
            Smazat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ItemAmmountChanger({ onChange, ammount }: { onChange: (newVal: number) => void; ammount: number }) {
  const [state, setState] = useState(ammount);
  return (
    <TextField
      value={state}
      style={{ width: "64px" }}
      type="number"
      onChange={(e) => setState(parseInt(e.target.value))}
      onBlur={() => onChange(state)}
    />
  );
}

function getMonthsUntilExp(item: FAKItem) {
  if (item === null || item === undefined) return 0;
  const today = new Date();
  let months = (new Date(item.expirationDate).getFullYear() - today.getFullYear()) * 12;
  months -= today.getMonth();
  months += new Date(item.expirationDate).getMonth();
  return months <= 0 ? 0 : months;
}

export function getSoonExpirationText(fak: FAK) {
  const soonExpCount = fak.items.filter((i) => getMonthsUntilExp(i) <= 2).length;
  if (soonExpCount <= 0) return "";
  if (soonExpCount === 1) return `1 produkt brzy expiruje`;
  if (soonExpCount < 5) return `${soonExpCount} produkty brzy expirují`;
  return `${soonExpCount} produktů brzy expiruje`;
}

export default function ({ fak, onUpdate, onDelete, admin, orderProducts }: IProps) {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const addItem = (item: FAKItem) => {
    let formData = new FormData();
    formData.append("name", item.name);
    formData.append("sukl", item.sukl);
    formData.append("expDate", JSON.stringify(item.expirationDate).replace('"', "").replace('"', ""));
    formData.append("ammount", item.ammount.toString());
    formData.append("fakId", fak.id.toString());
    fetch(`/api/fak/addItem.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res: FAKItem) => {
            onUpdate({ ...fak, items: [...fak.items, res] });
          });
        } else {
          alert("Při přidávání léku došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const nameChange = (newName: string) => {
    let formData = new FormData();
    formData.append("name", newName);
    formData.append("fakId", fak.id.toString());
    fetch(`/api/fak/changeName.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onUpdate({ ...fak, name: newName });
        } else {
          alert("Při měnění názvu došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const deleteFak = () => {
    let formData = new FormData();
    formData.append("fakId", fak.id.toString());
    fetch(`/api/fak/delete.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onDelete();
        } else {
          alert("Při mazání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const changeProp = (id: number, key: keyof FAKItem, prop: string, value: any, valToString: (value: any) => string) => {
    let formData = new FormData();
    formData.append("id", id.toString());
    formData.append("prop", prop);
    formData.append("value", valToString(value));
    fetch(`/api/fak/changeItemProp.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          const newItems = fak.items.map((item) => {
            if (id === item.id) {
              const newItem = { ...item };
              newItem[key] = value as never;
              return newItem;
            } else return item;
          });
          onUpdate({ ...fak, items: newItems });
        } else {
          alert("Při úpravě došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const deleteItem = (item: FAKItem) => {
    let formData = new FormData();
    formData.append("id", item.id.toString());
    fetch(`/api/fak/deleteItem.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onUpdate({ ...fak, items: fak.items.filter((i) => i.id !== item.id) });
        } else {
          alert("Při mazání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const orderExpItems = () => {
    let items: { sukl: string; ammount: number }[] = [];
    fak.items.forEach((i) => {
      if (getMonthsUntilExp(i) <= 3) {
        items.push({ sukl: i.sukl, ammount: i.ammount });
      }
    });
    orderProducts(items);
    setOpen(false);
  };

  const saveAdditionalMail = () => {
    let formData = new FormData();
    formData.append("mail", fak.additionalMail);
    formData.append("fakId", fak.id.toString());
    fetch(`/api/fak/changeAdditionalMail.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
        } else {
          alert("Při měnění emailu došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const changeSendAlerts = () => {
    let formData = new FormData();
    formData.append("send", !fak.sendAlerts ? "1" : "0");
    formData.append("fakId", fak.id.toString());
    fetch(`/api/fak/changeSendAlerts.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onUpdate({ ...fak, sendAlerts: !fak.sendAlerts });
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };
  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle style={{ minWidth: "512px" }}>
          <NameEditor edit={edit} onChange={nameChange} fakName={fak.name} />
          <Tooltip title={edit ? "Uložit změny" : "Upravit lékárničku"}>
            <IconButton
              style={{ float: "right" }}
              onClick={(e) => {
                e.stopPropagation();
                setEdit(!edit);
              }}
            >
              {edit ? <DoneIcon /> : <EditIcon />}
            </IconButton>
          </Tooltip>
          <br />
          <Typography variant="caption">{getSoonExpirationText(fak)}</Typography>
        </DialogTitle>
        <DialogContent>
          {fak.items.length === 0 && !edit && <Typography variant="caption">Lékárnička je momentálně prázdná</Typography>}
          <Table>
            <TableHead>
              {fak.items.length > 0 && (
                <TableRow>
                  <TableCell colSpan={2}>Produkt</TableCell>
                  <TableCell>Expirace</TableCell>
                  <TableCell>Množství</TableCell>
                  <TableCell> {edit || "Expiruje za"}</TableCell>
                  {!edit && <TableCell></TableCell>}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {fak.items.map((act) => (
                <TableRow key={act.id}>
                  <TableCell>{act.sukl}</TableCell>
                  <TableCell>{act.name}</TableCell>
                  <TableCell>
                    {edit ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="MM-yyyy"
                          views={["year", "month"]}
                          style={{ width: "120px" }}
                          autoOk={true}
                          value={act.expirationDate}
                          margin="normal"
                          onChange={(e) => {
                            if (e)
                              changeProp(act.id, "expirationDate", "Expiration", e, (date) =>
                                JSON.stringify(date).replace('"', "'").replace('"', "'")
                              );
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    ) : (
                      <span style={{ fontWeight: getMonthsUntilExp(act) <= 12 ? "bold" : "inherit" }}>
                        {dateformat(act.expirationDate, "mm/yy")}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {edit ? (
                      <ItemAmmountChanger
                        ammount={act.ammount}
                        onChange={(newAmmount) => changeProp(act.id, "ammount", "Ammount", newAmmount, (id) => id.toString())}
                      />
                    ) : (
                      <>{act.ammount}</>
                    )}
                  </TableCell>
                  {edit ? (
                    <TableCell>
                      <IconButton
                        style={{ float: "right", color: "red" }}
                        onClick={(e) => {
                          deleteItem(act);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <span style={{ fontWeight: getMonthsUntilExp(act) <= 12 ? "bold" : "inherit" }}>
                        {getMonthsUntilExp(act)} měs
                      </span>
                    </TableCell>
                  )}
                  {!edit && (
                    <TableCell>
                      {getMonthsUntilExp(act) <= 2 && (
                        <FAKExpProductRemove
                          alreadyExpired={getMonthsUntilExp(act) <= 0}
                          onDelete={() => deleteItem(act)}
                          onAddToCard={() => {
                            orderProducts([{ sukl: act.sukl, ammount: act.ammount }]);
                          }}
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {edit && <NewItemAdder addItem={addItem} />}
            </TableBody>
          </Table>
          <br />
          <br />
          {!edit ? (
            <>
              {fak.additionalMail && fak.additionalMail.length > 0 && (
                <Typography> Zasílat upozornění také na: {fak.additionalMail}</Typography>
              )}
              {!fak.sendAlerts && <Typography>Nezasílat upozornění na registrovaný email</Typography>}
            </>
          ) : (
            <>
              <TextField
                fullWidth
                label="Zasílat upozornění na další email"
                value={fak.additionalMail}
                onChange={(e) => onUpdate({ ...fak, additionalMail: e.target.value })}
                onBlur={saveAdditionalMail}
              />
              <FormControlLabel
                control={<Checkbox checked={!fak.sendAlerts} onChange={changeSendAlerts} color="primary" />}
                label="Nezasílat upozornění na registrovaný email"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Tooltip title="Toto talčítko automaticky přidá do ceníku všechny produkty z lékárničky, které již expirovaly nebo expirují do 2 měsíců.">
            <Button onClick={orderExpItems}>Doobjednat produkty</Button>
          </Tooltip>
          <PrintDownloader fak={fak} />
          {!admin && (
            <>
              <DeleteFak
                onDelete={() => {
                  deleteFak();
                  setOpen(false);
                }}
              />
            </>
          )}
          <Button
            onClick={() => {
              setEdit(false);
              setOpen(false);
            }}
            color="primary"
          >
            {edit ? "Uložit a zavřít" : "Zavřít"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
