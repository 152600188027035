import React from "react";

type addViewType = {
  src: string;
  addLink?: string;
};

export default function ({ src, addLink }: addViewType) {
  console.log(addLink);
  return addLink === undefined ? (
    <div className="addContainer">
      <img alt="" src={src} />
    </div>
  ) : (
    <a href={addLink} target="_blank" rel="noopener noreferrer">
      <div className="addContainer">
        <img alt="" src={src} />
      </div>
    </a>
  );
}
