import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { UserInformation } from "../../commonTypes";
import RegisterForm from "./UserInformationForm";
import config from "../../Enviroment";

export default function ({ history, checkLogin }: { history: any; checkLogin: () => void }) {
  const submitForm = (formState: UserInformation) => {
    let formData = new FormData();

    formData.append(
      "user",
      JSON.stringify({
        ...formState,
        Phone: formState.Phone.replace("+420 ", ""),
        DelPhone: formState.DelPhone?.replace("+420 ", ""),
        PayTax: formState.PayTax ? 1 : 0,
      })
    );
    fetch(`/api/users/create.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          checkLogin();
          history.push("/Home");
        } else {
          alert("Registrace se nezdařila. Zkuste to prosím později.");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <br />
      <Grid container>
        <Grid item xs={12} md={2}>
          <></>
        </Grid>
        <Grid item xs={12} md={8}>
          <Alert style={{ width: "100%" }} color="info">
            <Typography variant="body1" style={{ paddingLeft: "32px" }}>
              V případě potíží s registrací Vaší školy se, prosím, obracejte ohledně pomoci na naši Zákaznickou linku:
              545 230 805 nebo email: {config.mail} Jsme tu pro Vás pondělí až pátek 7:30-17:00, mimo svátky.
            </Typography>
          </Alert>
        </Grid>
      </Grid>
      <br />
      <RegisterForm onFormSubmit={submitForm} registartion={true} submitButtonText="Registrovat se" />
      <br />
      <Grid container>
        <Grid item xs={12} md={1}>
          <></>
        </Grid>
        {/* <Grid item xs={12} md={7}>
          <Alert style={{ width: "100%" }} color="warning">
            <Typography variant="body1" style={{ paddingLeft: "32px", textAlign: "justify" }}>
              Po kliknutí na tlačítko Registrovat se nám přijde do lékárny Vaše registrace. Tu zpravidla do 24 hodin
              překontrolujeme a přiřadíme Vaší škole Přehlednou nabídku produktů podle věku pro mateřské, základní a střední
              školy (každá individuální nabídka obsahuje tipy, rady a obvyklá upozornění od našich lékárníků vztahující se
              k věku pacientů). Po přiřazení Přehledné nabídky Vám na email přijde potvrzovací zpráva. Následně se budete moci
              přihlásit, spravovat a dovybavovat Vaše lékárničky.
            </Typography>
          </Alert>
        </Grid> */}
      </Grid>
    </>
  );
}
