import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@material-ui/core";

export default function ({ onDelete, email }: { onDelete: () => void; email: string }) {
  const [show, setShow] = useState(false);
  const resetState = () => {
    setShow(false);
  };

  const deleteUser = () => {
    let formData = new FormData();
    formData.append("email", email);
    fetch(`/api/users/delete.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onDelete();
          resetState();
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <Tooltip title="Smazat uživatele">
        <FontAwesomeIcon
          icon={faUserSlash}
          style={{ color: "red", cursor: "pointer", marginLeft: "16px" }}
          onClick={(e) => setShow(true)}
        />
      </Tooltip>

      <Dialog open={show} onClose={resetState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>Smazat uživatele</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Opravdu chcete smazat tohoto uživatele?
            <br />
            email: {email}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetState} color="default">
            Zrušit
          </Button>
          <Button onClick={deleteUser} style={{ color: "red" }}>
            Smazat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
