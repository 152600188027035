import { faMedkit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { urlFormatParams } from "../../common";
import { FAK } from "../../commonTypes";
import { commonClasses } from "../../styles";
import CreateFAK from "./CreateFAK";
import FAKInfo, { getSoonExpirationText } from "./FAKInfo";

interface IProps {
  selectedFAK: number;
  selectFAK: (fak: number) => void;
  admin: boolean;
  actMail: string;
  orderProducts: (prods: { sukl: string; ammount: number }[]) => void;
}

export default function ({ selectedFAK, selectFAK, admin, actMail, orderProducts }: IProps) {
  const styles = commonClasses();
  const [faks, setFaks] = useState<FAK[]>([]);

  useEffect(() => {
    const data: { [index: string]: string } = {};
    if (admin) data["email"] = actMail;
    fetch("/api/fak/get.php" + urlFormatParams(data))
      .then((res) =>
        res.json().then((list) => {
          if (list.length > 0) {
            setFaks(
              list.map((f: any) => {
                return { ...f, sendAlerts: f.sendAlerts === "1" };
              }) as FAK[]
            );
            selectFAK(list[0].id);
          }
        })
      )
      .catch(console.log);
  }, [selectFAK, actMail, admin]);

  if (admin && (actMail === undefined || actMail.length === 0)) return <></>;

  return (
    <>
      {faks.length === 0 && (
        <>
          <Alert severity="warning" style={{ width: "80%", marginLeft: "10%" }}>
            Před vytvořením objednávky si prosím vytvořte alespoň jednu lékárničku kliknitím na tlačítko "Přidat lékárničku", do
            které Vám můžeme objednané produkty automaticky uložit. Děkujeme.
          </Alert>
          <br />
        </>
      )}
      <Grid container alignItems="center" spacing={3} style={{ marginLeft: "20px", paddingRight: "64px" }}>
        {faks.map((fak, i) => (
          <Grid key={fak.id} item xs={12} sm={6} md={3} lg={2}>
            <Card style={{ cursor: "pointer" }} onClick={() => selectFAK(fak.id)}>
              <CardHeader
                avatar={<FontAwesomeIcon icon={faMedkit} />}
                className={fak.id === selectedFAK ? styles.fakCardHeader : styles.fakInactiveCardHeader}
                classes={{ subheader: styles.fakCardSubheader }}
                title={fak.name}
                subheader={`${getSoonExpirationText(fak)}`}
                action={
                  <FAKInfo
                    orderProducts={orderProducts}
                    fak={fak}
                    onUpdate={(updatedFak) => {
                      const newFaks = [...faks];
                      newFaks[i] = updatedFak;
                      setFaks(newFaks);
                    }}
                    onDelete={() => setFaks(faks.filter((x, index) => index !== i))}
                    admin={admin}
                  />
                }
              />
            </Card>
          </Grid>
        ))}
        <Grid item xs={1}>
          <CreateFAK
            onCreate={(fak: FAK) => {
              if (faks.filter((f) => fak.name === f.name).length > 0) {
                alert("Název lékárničky musí být unikátní");
                return;
              }
              setFaks([
                ...faks,
                {
                  id: fak.id,
                  name: fak.name,
                  items: [],
                  additionalMail: "",
                  sendAlerts: true,
                },
              ]);
            }}
            fakNames={faks.map((f) => f.name)}
            usrEmail={admin ? actMail : undefined}
          />
        </Grid>
      </Grid>
      {faks.filter((f) => f.id === selectedFAK)[0] && (
        <>
          <br />
          <br />
          <Typography style={{ marginLeft: "20px" }} variant="h6">
            Právě otevřená lékárnička: {faks.filter((f) => f.id === selectedFAK)[0].name}
          </Typography>
          <br />
        </>
      )}
    </>
  );
}
