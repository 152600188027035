import React, { useState, useEffect } from "react";
import PriceListSelector from "./SelectPriceList";
import UserDeletor from "./DeleteUser";
import { priceList, UserInformation } from "../../commonTypes";
import {
  Chip,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { localStorageKeys } from "../../storage";
import { useHistory } from "react-router-dom";

function ChildRow({ usr }: { usr: UserInformation }) {
  return (
    <TableRow>
      <TableCell colSpan={10}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h6">Fakturační adresa</Typography>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>Firma:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.CompanyName}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>Ulice:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.Street}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>ČP:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.HouseNum}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>PSČ:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.ZIP}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>Město:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.City}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Dodací adresa</Typography>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>Firma:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.DelCompanyName}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>Ulice:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.DelStreet}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>ČP:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.DelHouseNum}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>PSČ:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.DelZIP}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>Město:</b>
              </Grid>
              <Grid item xs={9}>
                {usr.DelCity}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            {usr.DelEmail && usr.DelEmail.length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <b>Email přebírajícího pracovníka:</b>
                </Grid>
                <Grid item xs={6}>
                  {usr.DelEmail}
                </Grid>
              </Grid>
            )}
            {usr.DelPhone && usr.DelPhone.length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <b>Telefon přebírajícího pracovníka:</b>
                </Grid>
                <Grid item xs={6}>
                  {usr.DelPhone}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
      justifyContent: "center",
      display: "flex",
    },
  },
}));

export default function () {
  const [users, setUsers] = useState<UserInformation[]>([]);
  const [showDetails, setShowDetails] = useState<string[]>([]);
  const [priceLists, setPriceLists] = useState<priceList[] | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuerry, setSearchQuerry] = useState("");
  const rowsPerPage = 20;
  const styles = useStyles();

  let history = useHistory();

  const loadPriceLists = () => {
    fetch("/api/priceLists/getAll.php")
      .then((res) => res.json())
      .then((data) => setPriceLists(data))
      .catch(console.log);
  };

  const approveUser = (i: number) => {
    let formData = new FormData();
    formData.append("approve", "true");
    formData.append("email", users[i].Email);
    fetch(`/api/users/approve.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          let newUsers = [...users];
          newUsers[i] = { ...users[i], Status: "Approved" };
          setUsers(newUsers);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetch("/api/users/getAll.php")
      .then((res) => res.json())
      .then((data) =>
        setUsers(
          data.map((d: any) => {
            return { ...d, PayTax: d.PayTax === "1" };
          })
        )
      )
      .catch(console.log);
  }, []);
  return (
    <>
      <TextField
        label="Vyhledat"
        style={{ float: "right" }}
        value={searchQuerry}
        onChange={(e) => setSearchQuerry(e.target.value)}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Jméno</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell></TableCell>
            <TableCell>DIČ</TableCell>
            <TableCell>DPH</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users
            .filter((usr: { [index: string]: any }) => {
              if (searchQuerry.length === 0) return true;
              return Object.keys(usr)
                .map((k) => (usr[k] || "").toString().toLowerCase().includes(searchQuerry.toLocaleLowerCase()))
                .reduce((acc, val) => acc || val, false);
            })
            .map((usr, index) => {
              return (
                index >= currentPage * rowsPerPage &&
                index < (currentPage + 1) * rowsPerPage && (
                  <React.Fragment key={usr.Email}>
                    <TableRow
                      className="pointer"
                      onClick={(e) => {
                        if (showDetails.includes(usr.Email)) {
                          setShowDetails(showDetails.filter((d) => d !== usr.Email));
                        } else {
                          setShowDetails([...showDetails, usr.Email]);
                        }
                      }}
                    >
                      <TableCell>
                        <span
                          style={{ textDecoration: "underline" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            localStorage.setItem(localStorageKeys.userToLoadKey, usr.Email);
                            history.push("/Stats");
                          }}
                        >
                          {usr.Name}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          style={{ textDecoration: "underline" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            localStorage.setItem(localStorageKeys.userToLoadKey, usr.Email);
                            history.push("/Home");
                          }}
                        >
                          {usr.Email}
                        </span>
                      </TableCell>
                      <TableCell>{usr.Phone}</TableCell>
                      <TableCell>{usr.CompanyId.replace(/\s/g, "")}</TableCell>
                      <TableCell>{usr.VAT}</TableCell>
                      <TableCell>{usr.PayTax ? "Ano" : "Ne"}</TableCell>
                      <TableCell>
                        {usr.Status === "Approved" ? (
                          <Chip color="primary" label="Schváleno" />
                        ) : (
                          <Chip label="Čekání na schválení" />
                        )}
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        {usr.Status === "Approved" ? (
                          <>
                            <PriceListSelector
                              priceLists={priceLists}
                              loadPriceLists={loadPriceLists}
                              actList={usr.PriceList || -1}
                              userEmail={usr.Email}
                              onPricelistChange={(newVal) => {
                                let newUsers = [...users];
                                newUsers[index] = { ...users[index], PriceList: newVal };
                                console.log(newUsers);
                                setUsers(newUsers);
                              }}
                            />
                            <UserDeletor
                              email={usr.Email}
                              onDelete={() => {
                                let newUsers = [...users];
                                newUsers.splice(index, 1);
                                setUsers(newUsers);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Tooltip title="Schválit uživatele">
                              <FontAwesomeIcon
                                icon={faUserCheck}
                                onClick={(e) => approveUser(index)}
                                style={{ cursor: "pointer", color: "green" }}
                              />
                            </Tooltip>
                            <UserDeletor
                              email={usr.Email}
                              onDelete={() => {
                                let newUsers = [...users];
                                newUsers.splice(index, 1);
                                setUsers(newUsers);
                              }}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    {showDetails.includes(usr.Email) && <ChildRow usr={usr} />}
                  </React.Fragment>
                )
              );
            })}
        </TableBody>
      </Table>
      {users.length > rowsPerPage && (
        <>
          <br />
          <div className={styles.root}>
            <Pagination
              style={{ textAlign: "center" }}
              count={Math.ceil(users.length / rowsPerPage)}
              color="primary"
              page={currentPage + 1}
              onChange={(e, v) => setCurrentPage(v - 1)}
            />
          </div>
        </>
      )}
    </>
  );
}
