type dateStirng = "Mo" | "Tu" | "We" | "Th" | "Fr";

//TODO type
export function reformatPriceListData(priceList: any) {
  var res = {
    tables: [] as any[], // TODO type
    gifts: priceList.gifts,
    info: priceList.info,
    images: priceList.images.filter((img: string) => img !== "." && img !== ".."),
  };
  for (var i in priceList.tables) {
    var act = priceList.tables[i];
    res.tables.push({
      Id: act[3],
      PriceList: act[5],
      Description: act[2],
      useSukl: act[7] === 1,
      useSuklPrice: act[8] === 1,
      useSingleDiscount: act[6] === 1,
      usePrice: act[4] === 1,
      useCommonPrice: act[1] === 1,
      add: act[0],
      minAmmount: act[9],
      isHidden: act[10] === 1,
      discounts: getPriceTableChildren(act[3], priceList.discounts),
      data: getPriceTableChildren(act[3], priceList.data),
    });
  }
  return res;
}

export function isNormalInteger(str: string) {
  var n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
}

// TODO type
function getPriceTableChildren(tblId: string, children: any) {
  let res = [];
  for (var i in children) {
    if (parseInt(children[i].PriceTable) === parseInt(tblId)) {
      res.push(children[i]);
    }
  }
  return res;
}

export function formatDate(date: Date) {
  return `${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`;
}

export function reformatNoDeliveryDate(date: Date) {
  return `${("0" + date.getDate().toString()).slice(-2)}-${("0" + (date.getMonth() + 1).toString()).slice(
    -2
  )}-${date.getFullYear()}`;
}

export function dayToNum(day: dateStirng) {
  if (day === "Mo") return 0;
  if (day === "Tu") return 1;
  if (day === "We") return 2;
  if (day === "Th") return 3;
  if (day === "Fr") return 4;
}

export function numToDay(day: number) {
  if (day === 0) return "Mo";
  if (day === 1) return "Tu";
  if (day === 2) return "We";
  if (day === 3) return "Th";
  if (day === 4) return "Fr";
}

export function numToDayCz(day: number) {
  if (day === 0) return "Po";
  if (day === 1) return "Út";
  if (day === 2) return "St";
  if (day === 3) return "Čt";
  if (day === 4) return "Pá";
}

export function numToFullDay(day: number) {
  if (day === 1) return "Pondělí";
  if (day === 2) return "Úterý";
  if (day === 3) return "Středa";
  if (day === 4) return "Čtvrtek";
  if (day === 5) return "Pátek";
}

export function separateThousands(x: string) {
  if (x === null || x === undefined) return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function urlFormatParams(params: { [index: string]: string }) {
  return (
    "?" +
    Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&")
  );
}

export function isWeekend(date: Date) {
  return [0, 6].includes(date.getDay());
}

function isMonday(date: Date) {
  return date.getDay() === 1;
}

export function getStartOfCurrentWeek() {
  let today = new Date();
  while (isWeekend(today)) today.setDate(today.getDate() + 1);
  while (!isMonday(today)) today.setDate(today.getDate() - 1);
  return today;
}
