import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProductPrice } from "../Order/PriceTable";
import { separateThousands } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ammountsType, calculateTotalPrice, getPremiumServicePrice } from "../Order/Order";
// eslint-disable-next-line
import { DiscountCode, FAK, gift, premiumService, priceTable, UserInformation } from "../../commonTypes";
import { localStorageKeys, orderStateStorageType } from "../../storage";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { formatExpiration, productTotalAmmount } from "../Order/PriceTable";
import config from "../../Enviroment";

function getNewOrderMailBody(orderInfo: string, userInfo: string) {
  return `<html>
            <body>
              <p>
                V Aplikaci Lékárničky pro ${config.institution} byla vytvořena nová objednávka.
              </p>
              <h2>Informace o objednávce</h2>
              ${orderInfo}
              <br>
              <h2>Informace o zákazníkovi</h2>
              ${userInfo}
            </body>
          </html>`;
}

function getOrderConfirmBody(orderInfo: string) {
  return `<html>
            <body>
              <p>
                Děkujeme za Vaši objednávku. V případě nedostupnosti nějakého produktu Vás kontaktujeme a domluvíme se na dalším postupu.
              </p>
              <h2>Informace o objednávce:</h2>
              ${orderInfo}
            </body>
          </html>`;
}

function formatUserInformation(user: UserInformation) {
  return `<table>
            <tbody>
              <tr>
                <td><b>Jméno</b></td>
                <td>${user.Name}</td>
              </tr>
              <tr>
                <td><b>Email</b></td>
                <td>${user.Email}</td>
              </tr>
              <tr>
                <td><b>Název společnosti</b></td>
                <td>${user.CompanyName}</td>
              </tr>
              <tr>
                <td><b>Fakturační adresa</b></td>
                <td>${user.Street} ${user.HouseNum}, ${user.ZIP} ${user.City}</td>
              </tr>
              <tr>
                <td><b>IČ</b></td>
                <td>${user.CompanyId.replace(/\s/g, "")}</td>
              </tr>
              <tr>
                <td><b>DIČ</b></td>
                <td>${user.VAT}</td>
              </tr>
              <tr>
                <td><b>Plátce DPH: </b></td>
                <td>${user.PayTax ? "Ano" : "Ne"}</td>
              </tr>
              <tr>
                <td><b>Název ${config.institution}</b></td>
                <td>${user.DelCompanyName}</td>
              </tr>
              <tr>
                <td><b>Dodací adresa</b></td>
                <td>${user.DelStreet} ${user.DelHouseNum}, ${user.DelZIP} ${user.DelCity}</td>
              </tr>
                <tr>
                <td><b>Telefon na pracovníka přebírajícího zásilky</b></td>
                <td>${user.DelPhone}</td>
              </tr>
              <tr>
                <td><b>Email na pracovníka přebírajícího zásilky</b></td>
                <td>${user.DelEmail}</td>
              </tr>
            </tbody>
          </table>`;
}

function formatOrderInformaton(
  gift: gift,
  formState: IState,
  totalPrice: number,
  totalAmmount: number,
  discountCodes: DiscountCode[],
  premiumServices: premiumService[]
) {
  let res = `<h2><b>Objednané zboží:</b></h2>
            <table>
              ${(document.getElementById("products") || {}).innerHTML}
            </table>`;
  res += `<br/><h2><b>Souhrnný přehled objednaného zboží:</b></h2>
          <table>
            ${(document.getElementById("productsRecap") || {}).innerHTML}
          </table>`;
  if (formState.moreProducts !== "" || formState.deliveryNote !== "" || formState.note !== "" || formState.tips !== "") {
    res += `<br/><h2><b>Doplňkové informace</b></h2>`;
  }
  if (formState.moreProducts !== "") {
    res += `<h3><b>Další objednané produkty:</b></h3>
                    <p>${formState.moreProducts}</p>`;
  }
  if (formState.deliveryNote !== "") {
    res += `<h3><b>Poznámka k datu dodání:</b></h3>
                      <p>${formState.deliveryNote}</p>`;
  }
  if (formState.note !== "") {
    res += `<h3><b>Poznámka</b></h3>
                    <p>${formState.note}</p>`;
  }
  if (formState.tips !== "") {
    res += `<h3><b>Tipy na zlepšení:</b></h3>
                    <p>${formState.tips}</p>`;
  }
  if (formState.internalNumber !== "") {
    res += `<h3><b>Interní číslo objednávky:</b></h3>
                    <p>${formState.internalNumber}</p>`;
  }
  if (premiumServices.length > 0) {
    res += `<h3> <b><u>Prémiové služby:</u></b> </h3><br/>
            ${premiumServices.map((ps) => `${ps.name}: ${ps.freeFrom <= totalPrice ? 0 : ps.price} Kč<br/>`)}`;
  }
  if (discountCodes.length > 0) {
    res += `<h3><b/>Aplikované slevové kódy</b></h3><br/>
    ${discountCodes.map(
      (d) => `
      <p>
        ${d.Code}: ${d.Description}
      </p>`
    )}`;
  }
  if (gift !== undefined) {
    res += `<h3><b> Dárek navíc:</b></h3><br/>
                    ${gift.name} (+ ${gift.price} Kč)<br/><br/><br/>`;
  }
  res += `<h3> <b>Celková cena objednávky:</b> ${separateThousands(totalPrice.toString())} Kč</h3><br/>`;
  res += `<h3> <b>Celkový odběr:</b> ${totalAmmount} ks </h3><br/>`;
  return res;
}

function getProduct(table: priceTable, prodId: number) {
  return table.data.filter((d) => parseInt(d.id.toString()) === prodId)[0];
}

function getTable(tables: priceTable[], tableId: number) {
  return tables.filter((t) => parseInt(t.id.toString()) === tableId)[0];
}

function getTotalAmmount(ammounts: ammountsType) {
  const sum = (acc: number, val: number) => acc + val;
  return Object.values(ammounts)
    .map((vals) =>
      Object.values(vals)
        .map((x) => Object.values(x).reduce(sum, 0))
        .reduce(sum, 0)
    )
    .reduce(sum, 0);
}

function addProductsToFAK(ammounts: ammountsType, tables: priceTable[]) {
  const groupedProducts = reformatProducts(ammounts);
  let formatedItems: { fakId: string; sukl: string; name: string; expDate: string; ammount: string }[] = [];
  Object.keys(groupedProducts).forEach((fakId) =>
    groupedProducts[fakId].forEach(({ productId, tableId, ammount }) => {
      const table = getTable(tables, tableId);
      const product = getProduct(table, productId);
      if (ammount > 0) {
        formatedItems.push({
          fakId: fakId,
          sukl: product.sukl,
          name: product.name,
          expDate: product.expiration,
          ammount: ammount.toString(),
        });
      }
    })
  );

  let formData = new FormData();

  formData.append("items", JSON.stringify(formatedItems));
  fetch(`/api/fak/addMoreItems.php`, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
      } else {
        alert(
          "Při synchronizaci Vaší lékárničky došlo chybě. Prosím překnotrolujte si, zda Vaše lékárničky obsahují všechny dokoupené produkty."
        );
      }
    })
    .catch(console.log);
}

function saveToHistory(email: string, ammount: number, price: number) {
  let formData = new FormData();

  formData.append("email", email);
  formData.append("ammount", ammount.toString());
  formData.append("price", price.toString());
  fetch(`/api/orders/add.php`, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
      } else {
      }
    })
    .catch(console.log);
}

function reformatProducts(ammounts: ammountsType) {
  const formatedProducts: { [index: string]: { productId: number; tableId: number; ammount: number }[] } = {};
  Object.keys(ammounts).forEach((tableAmmountsKey) => {
    Object.keys(ammounts[(tableAmmountsKey as unknown) as number]).forEach((productKey) => {
      Object.keys(ammounts[(tableAmmountsKey as unknown) as number][(productKey as unknown) as number]).forEach((fakId) => {
        if (formatedProducts[fakId] === undefined) formatedProducts[fakId] = [];
        formatedProducts[fakId].push({
          productId: parseInt(productKey),
          tableId: parseInt(tableAmmountsKey),
          ammount:
            ammounts[(tableAmmountsKey as unknown) as number][(productKey as unknown) as number][(fakId as unknown) as number],
        });
      });
    });
  });
  return formatedProducts;
}

function Products({
  ammounts,
  tables,
  discountCodes,
  faks,
}: {
  ammounts: ammountsType;
  tables: priceTable[];
  discountCodes: DiscountCode[];
  faks: FAK[];
}) {
  const formatedProducts = reformatProducts(ammounts);
  return (
    <table id="products">
      <thead>
        <tr>
          <th>
            <Typography variant="body1">Název</Typography>
          </th>
          <th>
            <Typography variant="body1">Množství</Typography>
          </th>
          <th>
            <Typography variant="body1">EXP</Typography>
          </th>
          <th>
            <Typography variant="body1">Cena za kus</Typography>
          </th>
          <th>
            <Typography variant="body1">Celková cena</Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(formatedProducts).map((fakId) => (
          <React.Fragment key={fakId}>
            <tr>
              <td colSpan={4}>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Lékárnička: {faks.filter((fak) => fak.id.toString() === fakId)[0]?.name}
                </Typography>
              </td>
            </tr>
            {formatedProducts[fakId]
              .filter((f) => f.ammount > 0)
              .map(({ productId, tableId, ammount }) => {
                let table = getTable(tables, tableId);
                let product = getProduct(table, productId);
                let price = getProductPrice(product, ammounts, table, discountCodes, parseInt(fakId));
                return (
                  <tr key={product.id}>
                    <td>
                      <Typography variant="body2">{product.name}</Typography>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Typography variant="body2">{ammount}</Typography>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Typography variant="body2">{formatExpiration(product.expiration)}</Typography>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Typography variant="body2">{separateThousands(price.toString())} Kč</Typography>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Typography variant="body2">
                        {separateThousands((Math.round(100 * price * ammount) / 100).toString())} Kč
                      </Typography>
                    </td>
                  </tr>
                );
              })}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

function ProductsRecap({
  ammounts,
  tables,
  discountCodes,
}: {
  ammounts: ammountsType;
  tables: priceTable[];
  discountCodes: DiscountCode[];
}) {
  return (
    <table id="productsRecap">
      <thead>
        <tr>
          <th>
            <Typography variant="body1">Název</Typography>
          </th>
          <th>
            <Typography variant="body1">Množství</Typography>
          </th>
          <th>
            <Typography variant="body1">EXP</Typography>
          </th>
          <th>
            <Typography variant="body1">Cena za kus</Typography>
          </th>
          <th>
            <Typography variant="body1">Celková cena</Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(ammounts).map((tableId) => {
          return Object.keys(ammounts[parseInt(tableId)]).map((prodId) => {
            let table = getTable(tables, parseInt(tableId));
            let product = getProduct(table, parseInt(prodId));
            let price = getProductPrice(
              product,
              ammounts,
              table,
              discountCodes,
              parseInt(Object.keys(ammounts[parseInt(tableId)][parseInt(prodId)])[0])
            );
            const ammount = productTotalAmmount(ammounts[parseInt(tableId)][parseInt(prodId)]);
            if (ammount <= 0) return <></>;
            return (
              <tr key={product.id}>
                <td>
                  <Typography variant="body2">{product.name}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography variant="body2">{ammount}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography variant="body2">{formatExpiration(product.expiration)}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography variant="body2">{separateThousands(price.toString())} Kč</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography variant="body2">
                    {separateThousands((Math.round(100 * price * ammount) / 100).toString())} Kč
                  </Typography>
                </td>
              </tr>
            );
          });
        })}
      </tbody>
    </table>
  );
}

function sendEmailToAdmins(subject: string, body: string) {
  let formData = new FormData();
  formData.append("subject", subject);
  formData.append("body", body);
  fetch("/api/mails/sendEmailToAdmins.php", {
    method: "POST",
    body: formData,
  });
}

function sendEmailToUser(subject: string, body: string) {
  let formData = new FormData();
  formData.append("subject", subject);
  formData.append("body", body);
  fetch("/api/mails/sendEmailToLoggedUser.php", {
    method: "POST",
    body: formData,
  });
}

function sendEmail(to: string, subject: string, body: string) {
  let formData = new FormData();
  formData.append("subject", subject);
  formData.append("body", body);
  formData.append("email", to);
  fetch("/api/mails/sendEmail.php", {
    method: "POST",
    body: formData,
  });
}

interface IState {
  mails: string[];
  moreProducts: string;
  note: string;
  tips: string;
  deliveryNote: string;
  internalNumber: string;
}

export default function ({ history }: { history: any }) {
  const [formState, setFormState] = useState<IState>({
    mails: [""],
    moreProducts: "",
    note: "",
    tips: "",
    deliveryNote: "",
    internalNumber: "",
  });
  const [faks, setFaks] = useState<FAK[]>([]);

  let sotredData = JSON.parse(sessionStorage.getItem(localStorageKeys.OrderStateKey) as string) as orderStateStorageType;
  const getTotalPrice = () => {
    let price = calculateTotalPrice(sotredData.priceList.tables, sotredData.ammounts, sotredData.discountCodes).price;
    price += sotredData.selectedServices
      .map((i) => getPremiumServicePrice(sotredData.priceList.premiumServices[i], price))
      .reduce((n, sum) => sum + n, 0);
    return sotredData.selectedGiftId !== -1 ? price + sotredData.priceList.gifts[sotredData.selectedGiftId].price : price;
  };

  const order = () => {
    fetch("/api/users/get.php")
      .then((res) => {
        res.json().then((userData) => {
          const userInfo = formatUserInformation(userData);
          const orderInfo = formatOrderInformaton(
            sotredData.priceList.gifts[sotredData.selectedGiftId],
            formState,
            getTotalPrice(),
            getTotalAmmount(sotredData.ammounts),
            sotredData.discountCodes,
            sotredData.selectedServices.map((ss) => sotredData.priceList.premiumServices[ss])
          );
          const adminMail = getNewOrderMailBody(orderInfo, userInfo);
          const customerMail = getOrderConfirmBody(orderInfo);
          sendEmailToAdmins("Máte novou objednávku", adminMail);
          sendEmailToUser(`Potvrzení objednávky v Aplikaci Lékárničky pro ${config.institution}`, customerMail);
          formState.mails
            .filter((m) => m.length > 0)
            .forEach((m) => sendEmail(m, `Potvrzení objednávky v Aplikaci Lékárničky pro ${config.institution}`, customerMail));
          alert("Objednávka byla odeslána");
          addProductsToFAK(sotredData.ammounts, sotredData.priceList.tables);
          saveToHistory(userData.Email, getTotalAmmount(sotredData.ammounts), getTotalPrice());
          sessionStorage.clear();
          history.push("/Home");
        });
      })
      .catch(console.log);
  };
  if (sotredData === null)
    return (
      <p>
        Platnost stránky již vypršela, prosím vytvořte novou objednávku: <Link to="/">Vytvoření objednávky</Link>
      </p>
    );

  useEffect(() => {
    fetch("/api/fak/get.php")
      .then((res) =>
        res.json().then((list: FAK[]) => {
          if (list.length > 0) {
            setFaks(list);
          }
        })
      )
      .catch(console.log);
  }, []);

  return (
    <Grid style={{ overflowX: "auto" }} container spacing={3}>
      <Grid item xs={12} lg={6}>
        <div style={{ maxHeight: "75vh", overflow: "auto", direction: "rtl" }}>
          <div style={{ direction: "ltr" }}>
            <div>
              <Typography variant="h6">Vybrali jste si následující produkty:</Typography>
              <br />
              <Products
                ammounts={sotredData.ammounts}
                discountCodes={sotredData.discountCodes}
                tables={sotredData.priceList.tables}
                faks={faks}
              />
            </div>
            <br />
            <div>
              <Typography variant="h6">Celková rekapitulace objednaných produktů:</Typography>
              <br />
              <ProductsRecap
                ammounts={sotredData.ammounts}
                discountCodes={sotredData.discountCodes}
                tables={sotredData.priceList.tables}
              />
            </div>
            <br />
            {sotredData.discountCodes.length > 0 ? (
              <>
                <br />
                <div>
                  <h3>Aplikovali jste následující slevové kódy</h3>
                  {sotredData.discountCodes.map((d) => (
                    <p key={d.Code}>
                      {d.Code}: {d.Description}
                    </p>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
            <br />
            {sotredData.selectedGiftId !== -1 ? (
              <>
                <div>
                  <Typography variant="h6">K tomu dostanete jako dárek:</Typography>
                  <br />
                  <Typography variant="body2" style={{ marginLeft: "14px" }}>
                    {sotredData.priceList.gifts[sotredData.selectedGiftId].name} (+
                    {sotredData.priceList.gifts[sotredData.selectedGiftId].price} Kč)
                  </Typography>
                </div>
                <br />
              </>
            ) : (
              <></>
            )}
            <br />
            {sotredData.selectedServices.length > 0 && (
              <>
                <div>
                  <Typography variant="h6">Objednané prémiové služby:</Typography>
                  <br />

                  {sotredData.selectedServices.map((i) => (
                    <Typography variant="body2" style={{ marginLeft: "14px" }}>
                      {sotredData.priceList.premiumServices[i].name}
                      {getPremiumServicePrice(sotredData.priceList.premiumServices[i], getTotalPrice()) > 0 &&
                        `: ${sotredData.priceList.premiumServices[i].price} Kč`}
                    </Typography>
                  ))}
                </div>
                <br />
              </>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <br />
        <Typography variant="h6">
          Interní číslo objednávky. <small>Toto číslo bude uvedeno na faktuře.</small>
        </Typography>
        <br />
        <TextField
          multiline
          fullWidth
          value={formState.internalNumber}
          onChange={(e) => setFormState({ ...formState, internalNumber: e.target.value })}
        />
        <br />
        {sotredData.priceList.settings.showMoreGoods ? (
          <>
            <br />
            <Typography variant="h6">Chcete k objednávce jiné produkty z lékárny, než jsou v ceníku?</Typography>
            <br />
            <TextField
              multiline
              fullWidth
              value={formState.moreProducts}
              onChange={(e) => setFormState({ ...formState, moreProducts: e.target.value })}
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <br />
        <Typography variant="h6">Chcete poslat potvrzení objednávky na další email?</Typography>
        <br />
        {Array.from(Array(formState.mails.length).keys()).map((i) => (
          <TextField
            style={{ width: "80%" }}
            key={i}
            onChange={(e) => {
              let newMails = [...formState.mails];
              newMails[i] = e.target.value;
              setFormState({ ...formState, mails: newMails });
            }}
            value={formState.mails[i]}
          />
        ))}
        <FontAwesomeIcon
          icon={faPlus}
          onClick={() => setFormState({ ...formState, mails: [...formState.mails, ""] })}
          style={{ color: "green", cursor: "pointer" }}
        />
        <br />
        <br />
        <Typography variant="h6">Máte nějaké tipy na rozšíření produktů v online nabídce a další vylepšení služeb?</Typography>
        <br />
        <TextField
          multiline
          fullWidth
          value={formState.tips}
          onChange={(e) => setFormState({ ...formState, tips: e.target.value })}
        />
        <br />
        <br />
        <Typography variant="h6">Máte nějakou poznámku k datu dodání?</Typography>
        <br />
        <TextField
          multiline
          fullWidth
          value={formState.deliveryNote}
          onChange={(e) => setFormState({ ...formState, deliveryNote: e.target.value })}
        />
        <br />
        <br />
        <br />
        <Typography variant="h6" className="right">
          Celková cena objednávky: {separateThousands(getTotalPrice().toString())} Kč
        </Typography>
        <br />
        <br />
        <br />
        <Button onClick={order} variant="contained" color="primary" style={{ marginRight: "16px" }} className="right">
          Objednat
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            localStorage.setItem(localStorageKeys.userToLoadKey, sotredData.selectedMail || "");
            history.goBack();
          }}
          style={{ marginRight: "16px" }}
          className="right"
        >
          Upravit objednávku
        </Button>
      </Grid>
    </Grid>
  );
}
