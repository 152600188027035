import React from "react";
import { separateThousands } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography } from "@material-ui/core";
import { gift } from "../../commonTypes";

interface IProps {
  gifts: gift[];
  selectedGift: number;
  totalPrice: number;
  selectGift: (id: number) => void;
}

export default function Gifts({ gifts, selectedGift, totalPrice, selectGift }: IProps) {
  return (
    <div id="giftsContainer" style={{ marginLeft: "32px" }}>
      {gifts.length > 0 ? (
        <>
          <Typography variant="h6">Vyberte si dárek navíc</Typography>
          <br />
        </>
      ) : (
        <>
          <br />
          <br />
        </>
      )}
      {gifts.map((g, i) => (
        <Grid
          container
          key={i}
          onClick={g.minAmmount <= totalPrice ? () => selectGift(i) : () => {}}
          className={g.minAmmount <= totalPrice ? "pointer" : ""}
        >
          <Grid item>
            <Typography variant="body1">
              {g.minAmmount <= totalPrice ? (
                selectedGift === i ? (
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#28a745" }} />
                ) : (
                  <FontAwesomeIcon icon={faCircle} style={{ color: "#6c757d" }} />
                )
              ) : (
                <FontAwesomeIcon icon={faTimesCircle} style={{ color: "#dc3545" }} />
              )}
              &nbsp;<b>{g.name}: </b> Při nákupu od {separateThousands(g.minAmmount.toString())} Kč za cenu{" "}
              {separateThousands(g.price.toString())} Kč (zbývá{" "}
              {separateThousands((Math.round(100 * Math.max(0, g.minAmmount - totalPrice)) / 100).toString())} Kč)
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
