import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faArrowUp, faArrowDown, faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { priceTable, priceTableFormat, product } from "../../commonTypes";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { constants } from "../../consts";
import ImportModal from "./ImportModal";
import ColorPicker from "material-ui-color-picker";

export function getEmptyDataRow(format: priceTableFormat): product {
  return {
    id: -1,
    sukl: "",
    name: "",
    alternative: "",
    expiration: `${new Date().getFullYear()}-${("0" + new Date().getMonth()).slice(-2)}`,
    price: 0,
    recomendedAmmount: 0,
    mixDiscounts: format.mixDiscounts.map((x) => 0),
    ammountDiscountsFrom: new Array(format.ammountDiscountsCount).fill(0),
    ammountDiscounts: new Array(format.ammountDiscountsCount).fill(0),
    commonPrice: "",
    suklPrice: "",
    remark: "",
    available: true,
    highlighted: false,
    orderRecAmmount: true,
    highlightColor: "rgba(255, 167, 202, 0.93)",
  };
}

function getEmptySeparator(format: priceTableFormat): product {
  return {
    id: -1,
    sukl: constants.separator,
    name: "",
    alternative: "",
    expiration: `${new Date().getFullYear()}-${("0" + new Date().getMonth()).slice(-2)}`,
    price: 0,
    recomendedAmmount: 0,
    mixDiscounts: format.mixDiscounts.map((x) => 0),
    ammountDiscountsFrom: new Array(format.ammountDiscountsCount).fill(0),
    ammountDiscounts: new Array(format.ammountDiscountsCount).fill(0),
    commonPrice: "",
    suklPrice: "",
    remark: "",
    available: true,
    highlighted: false,
    orderRecAmmount: true,
    highlightColor: "rgba(255, 167, 202, 0.93)",
  };
}

export function getEditorCols(format: priceTableFormat) {
  let res = 5;
  if (format.sukl) res += 1;
  if (format.basicPrice) res += 1;
  if (format.commonPrice) res += 1;
  if (format.suklPrice) res += 1;
  if (format.recommendedAmmount) res += 1;
  res += format.mixDiscounts.length;
  res += 2 * format.ammountDiscountsCount;
  return res;
}

interface IProps {
  onDelete: () => void;
  onChange: (table: priceTable) => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  table: priceTable;
  images: string[];
}

function EditorHeader({ onDelete, onChange, table, images, onMoveUp, onMoveDown }: IProps) {
  return (
    <>
      <DeleteIcon className="left" style={{ cursor: "pointer", color: "red" }} onClick={onDelete} />
      &nbsp;&nbsp;{" "}
      <TextField label="Název tabulky" value={table.name} onChange={(e) => onChange({ ...table, name: e.target.value })} />
      <span className="right">
        <FontAwesomeIcon icon={faArrowUp} className="text-primary pointer" onClick={onMoveUp} />
        <FontAwesomeIcon
          icon={faArrowDown}
          className="text-primary pointer"
          onClick={onMoveDown}
          style={{ marginRight: "16px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={table.settings.hide}
              onChange={(e) => onChange({ ...table, settings: { ...table.settings, hide: !table.settings.hide } })}
              color="primary"
            />
          }
          label="Schovat tabulku"
        />

        <FormControl style={{ marginRight: "16px", minWidth: "240px" }}>
          <InputLabel>Reklama</InputLabel>
          <Select
            value={table.settings.add}
            onChange={(e) => onChange({ ...table, settings: { ...table.settings, add: e.target.value as string | undefined } })}
          >
            <MenuItem value={undefined}>&nbsp;</MenuItem>
            {images.map((img) => (
              <MenuItem key={img} value={img}>
                {img}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {table.settings.add && (
          <>
            <TextField
              style={{ marginRight: "16px" }}
              label="Odkaz reklamy"
              type="text"
              value={table.settings.addLink || ""}
              onChange={(e) => onChange({ ...table, settings: { ...table.settings, addLink: e.target.value } })}
            />
          </>
        )}
        <TextField
          style={{ display: "inline-block", width: "64px", margin: "0 16px" }}
          type="number no-arrows"
          label="Minimální množství"
          value={table.settings.minAmmount}
          onChange={(e) => onChange({ ...table, settings: { ...table.settings, minAmmount: parseInt(e.target.value) } })}
        />
      </span>
    </>
  );
}

function EditorTableHeader({ table }: { table: priceTable }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell></TableCell>

        <TableCell></TableCell>
        {table.format.sukl && (
          <>
            <TableCell>SÚKL</TableCell>
            <TableCell>Levnější alt.</TableCell>
          </>
        )}
        <TableCell>Název</TableCell>
        <TableCell>Expirace</TableCell>
        {table.format.basicPrice && <TableCell>Cena</TableCell>}
        {table.format.mixDiscounts.map((d, i) => (
          <TableCell key={i}>{`Cena MIX ${d}+`}</TableCell>
        ))}
        {new Array(table.format.ammountDiscountsCount).fill(0).map((x) => (
          <>
            <TableCell>Množ. sleva od</TableCell>
            <TableCell>Sleva</TableCell>
          </>
        ))}
        {table.format.commonPrice && <TableCell>Běžná cena</TableCell>}
        {table.format.suklPrice && <TableCell>Cena SÚKL</TableCell>}
        {table.format.recommendedAmmount && <TableCell>Doporučené množství</TableCell>}
        <TableCell>Poznámka</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

function swapInArray(arr: any[], i: number, j: number) {
  let help = arr[i];
  arr[i] = arr[j];
  arr[j] = help;
}

function EditorTableBody({ onChange, table }: { onChange: (table: priceTable) => void; table: priceTable }) {
  const changeProp = (i: number, prop: keyof product, newVal: any) => {
    if (typeof newVal !== "string" && typeof newVal !== "object" && isNaN(newVal)) return;
    let newData = [...table.data];
    newData[i] = { ...table.data[i] };
    newData[i][prop] = newVal as never;
    onChange({ ...table, data: newData });
  };

  return (
    <TableBody>
      {table.data.map((d, i) => {
        return d.sukl === constants.separator ? (
          <TableRow key={i}>
            <TableCell>
              {i > 0 && (
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="text-primary pointer"
                  onClick={() => {
                    let newData = [...table.data];
                    swapInArray(newData, i, i - 1);
                    onChange({ ...table, data: newData });
                  }}
                />
              )}
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: "green", cursor: "pointer", margin: "4px" }}
                onClick={() => {
                  let newData = [...table.data];
                  newData.splice(i + 1, 0, getEmptyDataRow(table.format));
                  onChange({ ...table, data: newData });
                }}
              />
              <FontAwesomeIcon
                icon={faWindowMinimize}
                style={{ color: "blue", cursor: "pointer", margin: "4px" }}
                onClick={() => {
                  let newData = [...table.data];
                  newData.splice(i + 1, 0, getEmptySeparator(table.format));
                  onChange({ ...table, data: newData });
                }}
              />
              {i < table.data.length - 1 && (
                <FontAwesomeIcon icon={faArrowDown} className="text-primary pointer" onClick={() => {}} />
              )}
            </TableCell>
            <TableCell colSpan={getEditorCols(table.format) + 1}>
              {
                <input
                  className="editorFormControll"
                  type="text"
                  value={d.name}
                  onChange={(e) => changeProp(i, "name", e.target.value)}
                  style={{ minWidth: "220px" }}
                />
              }
            </TableCell>
            <TableCell>
              <FontAwesomeIcon
                icon={faMinus}
                className="text-danger pointer"
                onClick={(e) => {
                  let newData = [...table.data];
                  newData.splice(i, 1);
                  onChange({ ...table, data: newData });
                }}
              />
            </TableCell>
          </TableRow>
        ) : (
          <TableRow key={i}>
            <TableCell style={{ minWidth: "54px" }}>
              {i > 0 && (
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="text-primary pointer"
                  onClick={() => {
                    let newData = [...table.data];
                    swapInArray(newData, i, i - 1);
                    onChange({ ...table, data: newData });
                  }}
                />
              )}
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: "green", cursor: "pointer", margin: "4px" }}
                onClick={() => {
                  let newData = [...table.data];
                  newData.splice(i + 1, 0, getEmptyDataRow(table.format));
                  onChange({ ...table, data: newData });
                }}
              />
              <FontAwesomeIcon
                icon={faWindowMinimize}
                style={{ color: "blue", cursor: "pointer", margin: "4px" }}
                onClick={() => {
                  let newData = [...table.data];
                  newData.splice(i + 1, 0, getEmptySeparator(table.format));
                  onChange({ ...table, data: newData });
                }}
              />
              {i < table.data.length - 1 && (
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="text-primary pointer"
                  onClick={() => {
                    let newData = [...table.data];
                    swapInArray(newData, i, i + 1);
                    onChange({ ...table, data: newData });
                  }}
                />
              )}
            </TableCell>
            <TableCell>
              <input type="checkbox" checked={d.available} onChange={(e) => changeProp(i, "available", !d.available)} />
            </TableCell>
            <TableCell>
              <>
                <input
                  type="checkbox"
                  className="editorIsHighlighted"
                  checked={d.highlighted}
                  onChange={(e) => changeProp(i, "highlighted", !d.highlighted)}
                />
                {d.highlighted && (
                  <ColorPicker
                    name="color"
                    value={d.highlightColor}
                    style={{ backgroundColor: d.highlightColor }}
                    onChange={(color) => {
                      console.log(color);
                      changeProp(i, "highlightColor", color);
                    }}
                  />
                )}
              </>
            </TableCell>
            {table.format.sukl && (
              <>
                <TableCell>
                  {
                    <input
                      className="editorFormControll"
                      type="text"
                      value={d.sukl}
                      onChange={(e) => changeProp(i, "sukl", e.target.value)}
                    />
                  }
                </TableCell>
                <TableCell>
                  {
                    <input
                      className="editorFormControll"
                      type="text"
                      value={d.alternative}
                      onChange={(e) => changeProp(i, "alternative", e.target.value)}
                    />
                  }
                </TableCell>
              </>
            )}
            <TableCell>
              {
                <input
                  className="editorFormControll"
                  type="text"
                  value={d.name}
                  onChange={(e) => changeProp(i, "name", e.target.value)}
                  style={{ minWidth: "248px" }}
                />
              }
            </TableCell>
            <TableCell>
              {
                <input
                  style={{ maxWidth: "148px" }}
                  className="editorFormControll"
                  type="month"
                  value={d.expiration}
                  onChange={(e) => changeProp(i, "expiration", e.target.value)}
                />
              }
            </TableCell>
            {table.format.basicPrice && (
              <TableCell>
                {
                  <input
                    className="editorFormControll no-arrows"
                    type="number"
                    value={d.price}
                    onChange={(e) => changeProp(i, "price", parseFloat(e.target.value))}
                  />
                }
              </TableCell>
            )}
            {table.format.mixDiscounts.map((dis, ind) => (
              <TableCell key={ind}>
                {
                  <input
                    className="editorFormControll no-arrows"
                    type="number"
                    value={d.mixDiscounts[ind]}
                    onChange={(e) => {
                      let newMD = [...d.mixDiscounts];
                      newMD[ind] = parseInt(e.target.value);
                      changeProp(i, "mixDiscounts", newMD);
                    }}
                  />
                }
              </TableCell>
            ))}
            {Array.from(Array(table.format.ammountDiscountsCount).keys()).map((ind) => (
              <>
                <TableCell>
                  {
                    <input
                      className="editorFormControll no-arrows"
                      type="number"
                      value={d.ammountDiscountsFrom[ind]}
                      onChange={(e) => {
                        let newArr = [...d.ammountDiscountsFrom];
                        newArr[ind] = parseInt(e.target.value);
                        changeProp(i, "ammountDiscountsFrom", newArr);
                      }}
                    />
                  }
                </TableCell>
                <TableCell>
                  {
                    <input
                      className="editorFormControll no-arrows"
                      type="number"
                      value={d.ammountDiscounts[ind]}
                      onChange={(e) => {
                        let newArr = [...d.ammountDiscounts];
                        newArr[ind] = parseInt(e.target.value);
                        changeProp(i, "ammountDiscounts", newArr);
                      }}
                    />
                  }
                </TableCell>
              </>
            ))}
            {table.format.commonPrice && (
              <TableCell>
                {
                  <input
                    className="editorFormControll"
                    type="text"
                    value={d.commonPrice}
                    onChange={(e) => changeProp(i, "commonPrice", e.target.value)}
                  />
                }
              </TableCell>
            )}
            {table.format.suklPrice && (
              <TableCell>
                {
                  <input
                    className="editorFormControll"
                    type="text"
                    value={d.suklPrice}
                    onChange={(e) => changeProp(i, "suklPrice", e.target.value)}
                  />
                }
              </TableCell>
            )}
            {table.format.recommendedAmmount && (
              <TableCell>
                <>
                  <input
                    className="editorFormControll"
                    type="text"
                    value={d.recomendedAmmount}
                    onChange={(e) => changeProp(i, "recomendedAmmount", e.target.value)}
                  />
                  <input
                    type="checkbox"
                    checked={d.orderRecAmmount === undefined ? true : d.orderRecAmmount}
                    onChange={() => changeProp(i, "orderRecAmmount", !d.orderRecAmmount)}
                  />
                </>
              </TableCell>
            )}
            <TableCell>
              <input
                className="editorFormControll"
                type="text"
                value={d.remark}
                onChange={(e) => changeProp(i, "remark", e.target.value)}
                style={{ minWidth: "380px" }}
              />
            </TableCell>

            <TableCell>
              <FontAwesomeIcon
                icon={faMinus}
                className="text-danger pointer"
                onClick={(e) => {
                  let newData = [...table.data];
                  newData.splice(i, 1);
                  onChange({ ...table, data: newData });
                }}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

export default function ({ onDelete, onChange, table, images, onMoveUp, onMoveDown }: IProps) {
  return (
    <>
      <EditorHeader
        onDelete={onDelete}
        onMoveUp={onMoveUp}
        onMoveDown={onMoveDown}
        onChange={onChange}
        table={table}
        images={images}
      />
      <Table>
        <EditorTableHeader table={table} />
        <EditorTableBody table={table} onChange={onChange} />
      </Table>
      <br />
      &nbsp; &nbsp;
      <FontAwesomeIcon
        style={{ color: "green", cursor: "pointer" }}
        icon={faPlus}
        onClick={() => {
          onChange({ ...table, data: [...table.data, getEmptyDataRow(table.format)] });
        }}
      />
      &nbsp; &nbsp;
      <FontAwesomeIcon
        style={{ color: "blue", cursor: "pointer" }}
        icon={faWindowMinimize}
        onClick={() => {
          onChange({ ...table, data: [...table.data, getEmptySeparator(table.format)] });
        }}
      />
      &nbsp;&nbsp;
      <ImportModal
        tableFormat={table.format}
        onImport={(newData) => {
          onChange({ ...table, data: [...table.data, ...newData] as product[] });
        }}
      />
      <br />
      <br />
    </>
  );
}
