import React, { useState } from "react";
import { Button, Modal, Fade, Card, CardHeader, CardContent, CardActions, TextField } from "@material-ui/core";
import dateformat from "dateformat";

export type addDiscoutCodeFormState = {
  code: string;
  desc: string;
  sukl: string;
  minAmmount: number;
  discount: number;
  validFrom: string;
  validTo: string;
};

export default function ({ onCreate }: { onCreate: (formState: addDiscoutCodeFormState) => void }) {
  const emptyState = {
    code: "",
    desc: "",
    sukl: "",
    minAmmount: 0,
    discount: 0,
    validFrom: dateformat(new Date(), "yyyy-mm-dd"),
    validTo: dateformat(new Date(), "yyyy-mm-dd"),
  };

  const [show, setShow] = useState(false);
  const [formState, setFormState] = useState(emptyState);

  const resetState = () => {
    setShow(false);
    setFormState(emptyState);
  };
  const createPriceList = () => {
    if (formState.code === "") {
      alert("Zadejte slevový kód");
      return;
    }
    let formData = new FormData();
    formData.append("code", formState.code);
    formData.append("discount", formState.discount.toString());
    formData.append("validFrom", formState.validFrom);
    formData.append("validTo", formState.validTo);
    formData.append("sukl", formState.sukl);
    formData.append("description", formState.desc);
    formData.append("minAmmount", formState.minAmmount.toString());
    fetch(`/api/discountCodes/create.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onCreate(formState);
          resetState();
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <br />
      <Button variant="contained" color="primary" className="right" onClick={() => setShow(true)}>
        Přidat slevový kód
      </Button>
      <br />

      <Modal open={show} onClose={() => setShow(false)} style={{ width: "40%", left: "30%", top: "20%" }} closeAfterTransition>
        <Fade in={show}>
          <Card>
            <CardHeader title="Přidat slevový kód" />
            <CardContent>
              <TextField
                fullWidth
                label="Slevový kód"
                value={formState.code}
                onChange={(e) => setFormState({ ...formState, code: e.target.value })}
              />

              <TextField
                fullWidth
                label="Popisek"
                value={formState.desc}
                onChange={(e) => setFormState({ ...formState, desc: e.target.value })}
              />

              <TextField
                fullWidth
                label="Súkl produktu (nechte prázdné pro celý ceník)"
                value={formState.sukl}
                onChange={(e) => setFormState({ ...formState, sukl: e.target.value })}
              />

              <TextField
                fullWidth
                label="Minimální odběr produktu pro aplikaci kódu"
                value={formState.minAmmount}
                onChange={(e) => setFormState({ ...formState, minAmmount: parseInt(e.target.value) })}
              />
              <TextField
                type="number"
                fullWidth
                label="Slava po aplikaci kódu"
                value={formState.discount}
                onChange={(e) => setFormState({ ...formState, discount: parseInt(e.target.value) })}
              />
              <TextField
                label="Platnost od"
                fullWidth
                type="date"
                value={formState.validFrom}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormState({ ...formState, validFrom: e.target.value })}
              />
              <TextField
                label="Platnost do"
                fullWidth
                type="date"
                value={formState.validTo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormState({ ...formState, validTo: e.target.value })}
              />
            </CardContent>
            <CardActions>
              <Button variant="contained" color="secondary" onClick={resetState}>
                Zrušit
              </Button>
              <Button variant="contained" color="primary" onClick={createPriceList}>
                Vytvořit
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
