import React from "react";
import { DeliveryDays, priceListSettings } from "../../commonTypes";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

interface IProps {
  settings: priceListSettings;
  name: string;
  onSettingsChange: (changedKey: keyof priceListSettings, newVal: any) => void;
  onNameChange: (newName: string) => void;
  images: string[];
}

export default function ({ settings, name, onSettingsChange, onNameChange, images }: IProps) {
  const deliveryDaysChange = (day: keyof DeliveryDays) => {
    let newDelDays = { ...settings.DeliveryDays };
    newDelDays[day] = !settings.DeliveryDays[day];
    onSettingsChange("DeliveryDays", newDelDays);
  };
  const prepDeliveryDaysChange = (day: keyof DeliveryDays) => {
    let newDelDays = { ...settings.PreparedDeliveryDays };
    newDelDays[day] = !settings.PreparedDeliveryDays[day];
    onSettingsChange("PreparedDeliveryDays", newDelDays);
  };

  return (
    <Card>
      <CardHeader title="Nastavení ceníku" />
      <CardContent>
        <TextField label="Název" fullWidth value={name} onChange={(e) => onNameChange(e.target.value)} />
        {
          //TODO date
        }
        <TextField
          label="Platnost od"
          fullWidth
          type="date"
          value={settings.validFrom}
          onChange={(e) => onSettingsChange("validFrom", e.target.value)}
        />
        <TextField
          fullWidth
          label="Minimální odběr"
          type="number"
          value={settings.minAmmount}
          onChange={(e) => onSettingsChange("minAmmount", parseInt(e.target.value))}
        />
        <TextField
          fullWidth
          label="Minimální odběr osobní převzetí"
          type="number"
          value={settings.minAmmountPersonal}
          onChange={(e) => onSettingsChange("minAmmountPersonal", parseInt(e.target.value))}
        />
        <FormControl fullWidth>
          <InputLabel>Reklama</InputLabel>
          <Select
            value={settings.add?.toString()}
            onChange={(e) => onSettingsChange("add", e.target.value as string | undefined)}
          >
            <MenuItem value={undefined}>&nbsp;</MenuItem>
            {images.map((img) => (
              <MenuItem key={img} value={img.toString()}>
                {img}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {settings.add && (
          <>
            <Typography variant="caption">Vybraná reklama: {settings.add}</Typography>
            <TextField
              fullWidth
              label="Odkaz reklamy"
              value={settings.addLink || ""}
              onChange={(e) => onSettingsChange("addLink", e.target.value)}
            />
          </>
        )}
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={settings.useDiscountCodes || false}
              onClick={() => onSettingsChange("useDiscountCodes", !(settings.useDiscountCodes || false))}
            />
          }
          label="Slevový kód"
        />
        <br />
        <br />
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Typography variant="caption">Rozvážíme v</Typography>
            <br />
            <ButtonGroup color="primary">
              <Button variant={settings.DeliveryDays.Mo ? "contained" : "outlined"} onClick={(e) => deliveryDaysChange("Mo")}>
                Po
              </Button>
              <Button variant={settings.DeliveryDays.Tu ? "contained" : "outlined"} onClick={(e) => deliveryDaysChange("Tu")}>
                Út
              </Button>
              <Button variant={settings.DeliveryDays.We ? "contained" : "outlined"} onClick={(e) => deliveryDaysChange("We")}>
                St
              </Button>
              <Button variant={settings.DeliveryDays.Th ? "contained" : "outlined"} onClick={(e) => deliveryDaysChange("Th")}>
                Čt
              </Button>
              <Button variant={settings.DeliveryDays.Fr ? "contained" : "outlined"} onClick={(e) => deliveryDaysChange("Fr")}>
                Pá
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="caption">Připravované rozvozové dny</Typography>
            <br />
            <ButtonGroup color="secondary">
              <Button
                variant={settings.PreparedDeliveryDays.Mo ? "contained" : "outlined"}
                onClick={(e) => prepDeliveryDaysChange("Mo")}
              >
                Po
              </Button>
              <Button
                variant={settings.PreparedDeliveryDays.Tu ? "contained" : "outlined"}
                onClick={(e) => prepDeliveryDaysChange("Tu")}
              >
                Út
              </Button>
              <Button
                variant={settings.PreparedDeliveryDays.We ? "contained" : "outlined"}
                onClick={(e) => prepDeliveryDaysChange("We")}
              >
                St
              </Button>
              <Button
                variant={settings.PreparedDeliveryDays.Th ? "contained" : "outlined"}
                onClick={(e) => prepDeliveryDaysChange("Th")}
              >
                Čt
              </Button>
              <Button
                variant={settings.PreparedDeliveryDays.Fr ? "contained" : "outlined"}
                onClick={(e) => prepDeliveryDaysChange("Fr")}
              >
                Pá
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
