import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { urlFormatParams } from "../../common";
import { FAK, UserInformation } from "../../commonTypes";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function getTableContent(user: UserInformation, fakName: string) {
  return {
    stack: [
      { text: [{ style: "bold", text: "Jméno: " }, user.Name] },
      { text: [{ style: "bold", text: "Email: " }, user.Email] },
      { text: [{ style: "bold", text: "Telefon: " }, user.Phone] },
      { text: [{ style: "bold", text: "IČ: " }, user.CompanyId] },
      { text: [{ style: "bold", text: "DIČ: " }, user.VAT] },
      { text: [{ style: "bold", text: "Plátce DPH: " }, (user.PayTax as any) === "1" ? "Ano" : "Ne"] },
      " ",
      {
        columns: [
          {
            stack: [
              { style: "bold", text: ["Fakturační adresa"] },
              { text: [{ style: "bold", text: "Firma: " }, user.CompanyName] },
              { text: [{ style: "bold", text: "Ulice: " }, user.Street] },
              { text: [{ style: "bold", text: "ČP: " }, user.HouseNum] },
              { text: [{ style: "bold", text: "PSČ: " }, user.ZIP] },
              { text: [{ style: "bold", text: "Město: " }, user.City] },
            ],
          },
          {
            stack: [
              { style: "bold", text: ["Dodací adresa"] },
              { text: [{ style: "bold", text: "Firma: " }, user.DelCompanyName] },
              { text: [{ style: "bold", text: "Ulice: " }, user.DelStreet] },
              { text: [{ style: "bold", text: "ČP: " }, user.DelHouseNum] },
              { text: [{ style: "bold", text: "PSČ: " }, user.DelZIP] },
              { text: [{ style: "bold", text: "Město: " }, user.DelCity] },
            ],
          },
        ],
      },
      " ",
      { text: [{ style: "bold", text: "Email pracovníka přebírajícího zásilku: " }, user.DelEmail] },
      { text: [{ style: "bold", text: "Telefon pracovníka přebírajícího zásilku: " }, user.DelPhone] },
      " ",
      " ",
      " ",
      " ",
      " ",
      { style: "fakName", text: ["Lékárnička: ", fakName] },
    ],
  };
}

export default function () {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [faks, setFaks] = useState<FAK[]>([]);
  const [selectedFaks, setSelectedFaks] = useState<number[]>([]);

  const loadFAKs = () => {
    setSelectedFaks([]);
    const data: { [index: string]: string } = {};
    data["email"] = email;
    fetch("/api/fak/get.php" + urlFormatParams(data))
      .then((res) =>
        res.json().then((list) => {
          if (list.length > 0) {
            setFaks(
              list.map((f: any) => {
                return { ...f, sendAlerts: f.sendAlerts === "1" };
              }) as FAK[]
            );
          }
        })
      )
      .catch(console.log);
  };

  const generatePdf = () => {
    fetch("/api/users/get.php?email=" + email).then((res) => {
      res.json().then((userData: UserInformation) => {
        pdfMake
          .createPdf({
            pageMargins: [0, 0, 0, 0],
            content: [
              {
                table: {
                  widths: [297, 297],
                  heights: selectedFaks
                    .filter((id, i) => i % 2 === 0)
                    .map((id, i) => {
                      return 412;
                    }),
                  body: selectedFaks
                    .filter((id, i) => i % 2 === 0)
                    .map((id, i) => {
                      return [
                        getTableContent(userData, faks.filter((fak) => fak.id === id)[0].name),
                        2 * i + 1 < selectedFaks.length
                          ? getTableContent(userData, faks.filter((fak) => fak.id === selectedFaks[2 * i + 1])[0].name)
                          : "",
                      ];
                    }),
                },
              },
            ],
            styles: {
              bold: {
                bold: true,
              },
              fakName: {
                fontSize: 18,
              },
            },
          })
          .download();
      });
    });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Generátor etiket
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email zákazníka"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={loadFAKs}
            autoFocus
          />
          {faks.map((fak) => (
            <FormControlLabel
              key={fak.id}
              control={
                <Checkbox
                  checked={selectedFaks.includes(fak.id)}
                  onChange={(e) => {
                    if (selectedFaks.includes(fak.id)) setSelectedFaks(selectedFaks.filter((i) => i !== fak.id));
                    else setSelectedFaks([...selectedFaks, fak.id]);
                  }}
                  color="primary"
                />
              }
              label={fak.name}
            />
          ))}
          <Button
            type="submit"
            disabled={selectedFaks.length === 0}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={generatePdf}
          >
            Generovat
          </Button>
        </div>
      </Container>
    </>
  );
}
