import { Button } from "@material-ui/core";
import React from "react";
import { UserInformation } from "../../commonTypes";
import EditForm from "../Register/UserInformationForm";

export default function () {
  const submitForm = (formState: UserInformation) => {
    let formData = new FormData();
    formData.append("user", JSON.stringify(formState));
    fetch(`/api/users/update.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Profil byl úspěšně změněn");
        } else {
          alert("Změna profilu se nezdařila. Zkuste to prosím později.");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <EditForm registartion={false} onFormSubmit={submitForm} submitButtonText="Změnit údaje" />
      <Button className="right" href="/#/Home" style={{ marginRight: "32px" }} color="primary">
        Zpět na hlavní stránku
      </Button>
      <br />
    </>
  );
}
