import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Button,
  Table,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  // Switch,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faMinus } from "@fortawesome/free-solid-svg-icons";
// import { reformatNoDeliveryDate } from "../../common";
import AddDiscountCode from "./AddDiscountCode";
import { addDiscoutCodeFormState } from "./AddDiscountCode";
import { DiscountCode /*, priceList*/, priceList } from "../../commonTypes";
import { DropzoneArea } from "material-ui-dropzone";
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";

export default function () {
  const [mails, setMails] = useState<string[]>([]);
  const [images, setImages] = useState<string[]>([]);
  // const [noDeliveryDays, setNoDeliveryDays] = useState<any>([]); // TODO type
  const [discountCodes, setDiscountCodes] = useState<DiscountCode[]>([]); // TODO type
  // const [priceLists, setPriceLists] = useState<priceList[]>([]); // TODO type
  const [newMail, setNewMail] = useState("");
  const [imgUpload, setImgUpload] = useState<File | undefined>(undefined);
  const [selectedList, setSelectedList] = useState(0);
  const [priceLists, setPriceLists] = useState<priceList[]>([]);
  // const [newNoDeliveryDay, setNewNoDeliveryDay] = useState<any>({ day: "", pricelist: "", remove: true }); // TODO type

  // const loadPriceLists = () => {
  //   fetch("/api/priceLists/getAll.php")
  //     .then((res) => res.json())
  //     .then((data) => setPriceLists(data))
  //     .catch(console.log);
  // };

  // const getPriceListById = (id: number | undefined | null) => {
  //   if (id === undefined || id === null) return null;
  //   return priceLists.filter((pl: { id: number }) => pl.id === id)[0]?.name;
  // };

  const loadMails = () => {
    fetch("/api/administration/getAdminEmails.php")
      .then((res) => res.json())
      .then((data) => {
        setMails(data);
      })
      .catch(console.log);
  };

  const loadDefaultPriceList = () => {
    fetch("/api/settings/get.php?key=defaultPriceList")
      .then((res) => res.text())
      .then((data) => {
        setSelectedList(parseInt(data));
      })
      .catch(console.log);
  };

  const loadPriceLists = () => {
    fetch("/api/priceLists/getAll.php")
      .then((res) => res.json())
      .then((data) => setPriceLists(data))
      .catch(console.log);
  };

  const addMail = () => {
    if (newMail.length === 0) {
      alert("Zadejte email");
      return;
    }
    let formData = new FormData();
    formData.append("email", newMail);
    fetch(`/api/administration/addAdminMail.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setMails([...mails, newMail]);
          setNewMail("");
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const deleteMail = (ind: number) => {
    let formData = new FormData();
    formData.append("email", mails[ind]);
    fetch(`/api/administration/removeAdminMail.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          let newMails = [...mails];
          newMails.splice(ind, 1);
          setMails(newMails);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const loadImages = () => {
    fetch("/api/administration/getImages.php")
      .then((res) => res.json())
      .then((data) => {
        setImages(data);
      })
      .catch(console.log);
  };

  const deleteImage = (ind: number) => {
    let formData = new FormData();
    formData.append("image", images[ind]);
    fetch(`/api/administration/deleteImage.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          let newImages = [...images];
          newImages.splice(ind, 1);
          setImages(newImages);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const uploadImage = () => {
    console.log(imgUpload);
    if (imgUpload === undefined) {
      alert("Vyberte obrázek");
      return;
    }
    let formData = new FormData();
    formData.append("files[]", imgUpload);
    fetch(`/api/administration/uploadImage.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setImages([...images, imgUpload.name]);
          setImgUpload(undefined);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  // const loadNoDeliveryDays = () => {
  //   fetch("/api/administration/getNoDeliveryDays.php")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setNoDeliveryDays(data.sort((a: any, b: any) => (a.Date < b.Date ? -1 : 1))); // TODO type
  //     })
  //     .catch(console.log);
  // };
  //
  // const addNoDeliveryDay = () => {
  //   if (newNoDeliveryDay.day === undefined || newNoDeliveryDay.day.length === 0) {
  //     alert("Vyberte datum");
  //     return;
  //   }
  //   let formData = new FormData();
  //   formData.append("day", reformatNoDeliveryDate(new Date(newNoDeliveryDay.day)));
  //   formData.append("priceListId", newNoDeliveryDay.pricelist);
  //   formData.append("remove", newNoDeliveryDay.remove ? "1" : "0");
  //   console.log(formData);
  //   fetch(`/api/administration/addNoDeliveryDay.php`, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         setNewNoDeliveryDay({ Date: "", Pricelist: "", Remove: true });
  //         loadNoDeliveryDays();
  //         setNewNoDeliveryDay({ day: "", pricelist: "", remove: true });
  //       } else {
  //         alert("Při ukládání došlo k chybě");
  //       }
  //     })
  //     .catch(console.log);
  // };

  // const removeNoDeliveryDay = (ind: number) => {
  //   let formData = new FormData();
  //   formData.append("id", noDeliveryDays[ind].Id);
  //   fetch(`/api/administration/removeNoDeliveryDay.php`, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         let newNoDD = [...noDeliveryDays];
  //         newNoDD.splice(ind, 1);
  //         setNoDeliveryDays(newNoDD);
  //       } else {
  //         alert("Při ukládání došlo k chybě");
  //       }
  //     })
  //     .catch(console.log);
  // };

  const loadDiscountCodes = () => {
    fetch("/api/discountCodes/get.php?all=true")
      .then((res) => res.json())
      .then((data) => {
        setDiscountCodes(data);
      })
      .catch(console.log);
  };

  const removeDiscountCode = (code: string) => {
    let formData = new FormData();
    formData.append("code", code);
    fetch(`/api/discountCodes/delete.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setDiscountCodes(discountCodes.filter((c: any) => c.Code !== code)); // TODO type
        } else {
          alert("Při mazání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const saveDefaultPriceList = () => {
    let formData = new FormData();
    formData.append("key", "defaultPriceList");
    formData.append("value", selectedList.toString());
    fetch(`/api/settings/set.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Ceník byl úspěšne změněn");
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  // TODO type
  const addDiscountCode = (formState: addDiscoutCodeFormState) => {
    setDiscountCodes([
      ...discountCodes,
      {
        Code: formState.code,
        Sukl: formState.sukl,
        Description: formState.desc,
        MinAmmount: formState.minAmmount,
        Discount: formState.discount,
        ValidFrom: formState.validFrom,
        ValidTo: formState.validTo,
      } as DiscountCode,
    ]);
  };

  useEffect(() => {
    loadMails();
    loadImages();
    // loadPriceLists();
    // loadNoDeliveryDays();
    loadDiscountCodes();
    loadPriceLists();
    loadDefaultPriceList();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {/* <Card>
          <CardHeader title="Výjimky v rozvozových dnech" />
          <CardContent>
            {noDeliveryDays.map((nd: any, i: number) => (
              <div key={nd.Id} style={{ color: nd.Remove ? "red" : "green" }}>
                <Typography variant="body1">
                  <FontAwesomeIcon onClick={(e) => removeNoDeliveryDay(i)} icon={faMinus} className="pointer text-danger" />
                  &nbsp; &nbsp; {nd.Date}: {getPriceListById(nd.PriceList) || "Všechny ceníky"}
                </Typography>
              </div>
            ))}
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    autoOk={true}
                    margin="normal"
                    value={newNoDeliveryDay.day}
                    onChange={(e) => {
                      if (e) setNewNoDeliveryDay({ ...newNoDeliveryDay, day: e });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                {" "}
                <FormControl fullWidth>
                  <InputLabel>Ceník</InputLabel>
                  <Select onChange={(e) => setNewNoDeliveryDay({ ...newNoDeliveryDay, pricelist: e.target.value })}>
                    <MenuItem value="">Všechny ceníky</MenuItem>
                    {priceLists.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" className="right" onClick={addNoDeliveryDay}>
                  Přidat
                </Button>
                <div className="right" style={{ marginRight: "8px" }}>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="body1">Rozvážíme</Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        checked={newNoDeliveryDay.remove}
                        onChange={(e) => setNewNoDeliveryDay({ ...newNoDeliveryDay, remove: !newNoDeliveryDay.remove })}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">Nerozvážíme</Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <br /> */}
        <Card>
          <CardHeader title="Obrázky" />
          <CardContent>
            {images.map((img, i) => (
              <Typography variant="body1" key={img}>
                <FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer", color: "red" }} onClick={(e) => deleteImage(i)} />
                &nbsp;&nbsp;{img}
              </Typography>
            ))}
            <br />
            <DropzoneArea filesLimit={1} onChange={(files) => setImgUpload(files.length > 0 ? files[0] : undefined)} />
            <Button color="primary" className="right" onClick={uploadImage}>
              Nahrát
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Emaily pro notifikace aplikace" />
          <CardContent>
            {mails.map((mail, i) => (
              <Typography variant="body1" key={mail}>
                <FontAwesomeIcon onClick={(e) => deleteMail(i)} icon={faMinus} style={{ cursor: "pointer", color: "red" }} />
                &nbsp;&nbsp;{mail}
              </Typography>
            ))}
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={9}>
                <TextField fullWidth label="Email" value={newMail} onChange={(e) => setNewMail(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Button variant="contained" color="primary" className="right" onClick={addMail}>
                  Přidat email
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <br />
        <Card>
          <CardHeader title="Slevové kódy" />
          <CardContent>
            {discountCodes.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Kód</TableCell>
                    <TableCell>Popis</TableCell>
                    <TableCell>Sleva pro</TableCell>
                    <TableCell>Sleva od</TableCell>
                    <TableCell>Sleva</TableCell>
                    <TableCell>Platnost od</TableCell>
                    <TableCell>Platnost do</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {discountCodes.map((c) => (
                    <TableRow key={c.Code}>
                      <TableCell>{c.Code}</TableCell>
                      <TableCell>{c.Description}</TableCell>
                      <TableCell>{c.Sukl}</TableCell>
                      <TableCell>{c.MinAmmount}</TableCell>
                      <TableCell>{c.Discount} Kč</TableCell>
                      <TableCell>{c.ValidFrom}</TableCell>
                      <TableCell>{c.ValidTo}</TableCell>
                      <TableCell>
                        <FontAwesomeIcon
                          onClick={() => removeDiscountCode(c.Code)}
                          style={{ color: "red", cursor: "pointer" }}
                          icon={faTrash}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            <AddDiscountCode onCreate={addDiscountCode}></AddDiscountCode>
          </CardContent>
        </Card>
        <br />
        <br />
        <Card>
          <CardHeader title="Výchozí ceník" />
          <CardContent>
            <FormControl fullWidth style={{ minWidth: "360px" }}>
              <InputLabel>Ceník</InputLabel>
              <Select value={selectedList || ""} onChange={(e) => setSelectedList(e.target.value as number)}>
                {priceLists.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button style={{ float: "right", margin: 12 }} variant="contained" color="primary" onClick={saveDefaultPriceList}>
              Uložit
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
