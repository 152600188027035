import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { localStorageKeys } from "../../storage";

type history = {
  email: string;
  ammount: string;
  price: string;
  date: string;
};

type statistics = {
  totalPrice: number;
  totalAmmount: number;
  numberOfOrders: number;
  averageAmmount: number;
  averagePrice: number;
  data: { [index: string]: { ammount: number; price: number } };
};

const emptyStats = {
  averageAmmount: 0,
  averagePrice: 0,
  numberOfOrders: 0,
  totalAmmount: 0,
  totalPrice: 0,
  data: {},
};

const formatData = (rowData: history[]) => {
  const res: { [index: string]: { ammount: number; price: number } } = {};
  rowData.forEach((h) => {
    if (res[h.date] === undefined) res[h.date] = { ammount: 0, price: 0 };
    res[h.date].ammount += parseInt(h.ammount);
    res[h.date].price += parseFloat(h.price);
  });
  return res;
};

const findStats = (rawData: history[]) => {
  const res: statistics = { ...emptyStats };
  res.numberOfOrders = rawData.length;
  rawData.forEach((h) => {
    res.totalAmmount += parseInt(h.ammount);
    res.totalPrice += parseFloat(h.price);
  });
  if (res.numberOfOrders > 0) {
    res.averageAmmount = Math.round(res.totalAmmount / res.numberOfOrders);
    res.averagePrice = Math.round(res.totalPrice / res.numberOfOrders);
  }
  res.data = formatData(rawData);
  return res;
};

const formatDate = (date: Date) => {
  return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`;
};

const parseDate = (raw: string) => {
  const year = raw.substring(0, 4);
  const month = raw.substring(4, 6);
  const day = raw.substring(6, 8);
  return new Date(parseInt(year), parseInt(month), parseInt(day));
};

export default function () {
  const [statisics, setStatistics] = useState<statistics>(emptyStats);
  const [mails, setMails] = useState<string[]>([]);
  const [selMail, setSelMail] = useState<string | undefined>(undefined);

  const loadStats = (email?: string) => {
    const appendix = email === undefined ? "" : "&email=" + email;
    fetch("/api/orders/get.php?from=20200101&to=" + formatDate(new Date()) + appendix)
      .then((res) => res.json())
      .then((resultData) => {
        setStatistics(findStats(resultData));
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (
      localStorage.getItem(localStorageKeys.userToLoadKey) !== undefined &&
      localStorage.getItem(localStorageKeys.userToLoadKey) !== null &&
      (localStorage.getItem(localStorageKeys.userToLoadKey) as string).length > 0
    ) {
      const mail = localStorage.getItem(localStorageKeys.userToLoadKey) as string;
      localStorage.setItem(localStorageKeys.userToLoadKey, "");
      setSelMail(mail);
      loadStats(mail);
    } else {
      loadStats();
    }
    fetch("/api/users/getAll.php")
      .then((res) => res.json())
      .then((resultData) => {
        setMails(resultData.map((u: any) => u.Email));
      })
      .catch(console.log);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
        <Plot
          style={{ width: "100%", height: "75vh" }}
          data={[
            {
              x: Object.keys(statisics.data).map((d) => parseDate(d)),
              y: Object.keys(statisics.data).map((d) => statisics.data[d].price),
              type: "scatter",
              mode: "lines+markers",
              marker: { color: "red" },
              name: "Cena",
            },
            {
              x: Object.keys(statisics.data).map((d) => parseDate(d)),
              y: Object.keys(statisics.data).map((d) => statisics.data[d].ammount),
              type: "scatter",
              mode: "lines+markers",
              name: "Množství",
            },
          ]}
          layout={{ title: "Nákupy v jednotlivých dnech" }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <br />
        <br />
        <FormControl fullWidth>
          <InputLabel>Zobrazený zákazník</InputLabel>
          <Select
            value={selMail || "Všechny nákupy"}
            onChange={(e) => {
              setSelMail(e.target.value as string | undefined);
              loadStats(e.target.value as string | undefined);
            }}
          >
            <MenuItem value={undefined}>Všechny nákupy</MenuItem>
            {mails.map((mail) => (
              <MenuItem key={mail} value={mail}>
                {mail}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Celková tržba
                </Typography>
                <Typography variant="h5" component="h2">
                  {statisics.totalPrice} Kč
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Celkově prodáno produktů
                </Typography>
                <Typography variant="h5" component="h2">
                  {statisics.totalAmmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Průměrná tržba za nákup
                </Typography>
                <Typography variant="h5" component="h2">
                  {statisics.averagePrice} Kč
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Průměrný počet produktů za nákup
                </Typography>
                <Typography variant="h5" component="h2">
                  {statisics.averageAmmount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Celkový počet nákupů
                </Typography>
                <Typography variant="h5" component="h2">
                  {statisics.numberOfOrders}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
