import { Button } from "@material-ui/core";
import React from "react";
import { FAK } from "../../commonTypes";
import dateformat from "dateformat";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function ({ fak }: { fak: FAK }) {
  const print = () => {
    pdfMake
      .createPdf({
        content: [
          {
            table: {
              body: [
                [{ text: `Lékárnička: ${fak.name}`, style: "tableHeader", colSpan: 3 }, {}, {}],
                [
                  { text: "Přípravek", style: "tableHeader" },
                  { text: "Expirace", style: "tableHeader" },
                  { text: "Množství", style: "tableHeader" },
                ],
                ...fak.items.map((i) => [i.name, dateformat(i.expirationDate, "mm/yy"), i.ammount]),
              ],
            },
          },
        ],
        styles: {
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: "black",
          },
        },
      })
      .download(`Lékárnička: ${fak.name}.pdf`);
  };

  return <Button onClick={print}>Stáhnout pro tisk</Button>;
}
