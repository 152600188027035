import React, { useState, useEffect } from "react";
import PriceTableCreate from "./CretePriceTableModal";
import PriceTableEditor from "./TableEditor";
import GiftAdder from "./NewGift";
import PremiumServiceAdder from "./NewPremiumService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import SettingsEditor from "./SettingsEditor";
import { Prompt } from "react-router-dom";
//eslint-disable-next-line
import { priceList, priceListSettings, priceTable, priceTableFormat } from "../../commonTypes";
import { Card, CardContent, CardHeader, Fab, Grid, Tooltip, Typography } from "@material-ui/core";

const emptyPriceList: priceList = {
  name: "",
  id: -1,
  tables: [],
  gifts: [],
  premiumServices: [],
  settings: {
    validFrom: "",
    minAmmount: 0,
    minAmmountPersonal: 0,
    add: undefined,
    showCalendar: true,
    showMoreGoods: true,
    RefundList: false,
    DeliveryDays: {
      Mo: true,
      Tu: true,
      We: true,
      Th: true,
      Fr: true,
    },
    PreparedDeliveryDays: {
      Mo: false,
      Tu: false,
      We: false,
      Th: false,
      Fr: false,
    },
  },
};

function createNewEmptyTable(name: string, format: priceTableFormat): priceTable {
  return {
    id: -1,
    data: [],
    name,
    format,
    settings: {
      hide: false,
      add: undefined,
      minAmmount: 0,
    },
  };
}

export default function PriceLists({ match }: { match: any }) {
  const [priceList, setPriceListCallBack] = useState<priceList>(emptyPriceList);
  const [images, setImages] = useState([]);
  const [changes, setChanges] = useState(false);

  const setPriceList = (data: priceList) => {
    setChanges(true);
    setPriceListCallBack(data);
  };

  useEffect(() => {
    fetch(`/api/priceLists/get.php?id=${match.params.id}`)
      .then((res) => res.json())
      .then((data) => {
        setPriceListCallBack(data);
      })
      .catch(console.log);
    fetch("/api/administration/getImages.php")
      .then((res) => res.json())
      .then((data) => setImages(data))
      .catch(console.log);
  }, [match.params.id]);

  const createPriceTable = (name: string, format: priceTableFormat) => {
    setPriceList({ ...priceList, tables: [...priceList.tables, createNewEmptyTable(name, format)] });
  };

  const savePriceList = (priceList: priceList) => {
    let formData = new FormData();
    formData.append("priceList", JSON.stringify(priceList));
    formData.append("id", priceList.id.toString());
    fetch(`/api/priceLists/update.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setChanges(false);
          alert("Změny byly uloženy");
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <Prompt when={changes} message="Jste si jistí, že chcete odejít? Všechny neuložené změny budou smazány." />
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <Card>
            <CardContent style={{ overflowX: "auto" }}>
              {priceList.tables.map((t, i) => (
                <>
                  <PriceTableEditor
                    key={i}
                    onChange={(newTable) => {
                      let newTables = [...priceList.tables];
                      newTables[i] = newTable;
                      setPriceList({ ...priceList, tables: newTables });
                    }}
                    onDelete={() => {
                      let newTables = [...priceList.tables];
                      newTables.splice(i, 1);
                      setPriceList({ ...priceList, tables: newTables });
                    }}
                    onMoveUp={() => {
                      if (i === 0) return;
                      const newTables = [...priceList.tables];
                      const tmp = newTables[i];
                      newTables[i] = newTables[i - 1];
                      newTables[i - 1] = tmp;
                      setPriceList({ ...priceList, tables: newTables });
                    }}
                    onMoveDown={() => {
                      if (i === priceList.tables.length - 1) return;
                      const newTables = [...priceList.tables];
                      const tmp = newTables[i];
                      newTables[i] = newTables[i + 1];
                      newTables[i + 1] = tmp;
                      setPriceList({ ...priceList, tables: newTables });
                    }}
                    table={t}
                    images={images}
                  />
                  <br />
                  <br />
                </>
              ))}
            </CardContent>
          </Card>
          <PriceTableCreate onCreate={createPriceTable} />
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6} lg={5}>
          <SettingsEditor
            settings={priceList.settings}
            name={priceList.name}
            onNameChange={(newName: string) => setPriceList({ ...priceList, name: newName })}
            onSettingsChange={(prop: keyof priceListSettings, newVal: any) => {
              let newSett = { ...priceList.settings };
              newSett[prop] = newVal as never;
              setPriceList({ ...priceList, settings: newSett });
            }}
            images={images}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Card>
            <CardHeader title="Dárky" />
            <CardContent>
              {priceList.gifts.map((g, i) => (
                <Grid container key={i}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <FontAwesomeIcon
                        icon={faMinus}
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => {
                          let newGifts = [...priceList.gifts];
                          newGifts.splice(i, 1);
                          setPriceList({ ...priceList, gifts: newGifts });
                        }}
                      />{" "}
                      {g.name}: od {g.minAmmount} Kč (cena: {g.price} Kč)
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </CardContent>
          </Card>
          <GiftAdder
            onCreate={(info) =>
              setPriceList({ ...priceList, gifts: [...priceList.gifts, info].sort((a, b) => a.minAmmount - b.minAmmount) })
            }
          />
          <br />
          <br />
          <br />
          <Card>
            <CardHeader title="Prémiové služby" />
            <CardContent>
              {priceList.premiumServices?.map((ps, i) => (
                <Grid container key={i}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <FontAwesomeIcon
                        icon={faMinus}
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => {
                          let newPS = [...priceList.premiumServices];
                          newPS.splice(i, 1);
                          setPriceList({ ...priceList, premiumServices: newPS });
                        }}
                      />{" "}
                      <Tooltip title={ps.subtitle}>
                        <>
                          {ps.name}: {ps.price} Kč (dostupné od {ps.minPrice} Kč, zdarma od {ps.freeFrom})
                          {ps.obligatory && ", poviná služba"}
                        </>
                      </Tooltip>
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </CardContent>
          </Card>
          <PremiumServiceAdder
            onCreate={(info) => setPriceList({ ...priceList, premiumServices: [...priceList.premiumServices, info] })}
          />
        </Grid>
      </Grid>
      <Fab
        style={{ position: "fixed", bottom: "32px", right: "32px" }}
        variant="extended"
        color="primary"
        onClick={() => savePriceList(priceList)}
      >
        Uložit změny
      </Fab>
    </>
  );
}
