import { makeStyles } from "@material-ui/core";

export const commonClasses = makeStyles((theme) => ({
  fakCard: {
    border: "1px solid #1976d2",
    marginLeft: "32px",
    marginRight: "32px",
  },
  fakCardHeader: {
    backgroundColor: "#1976d2",
    color: "#ffffff",
  },
  fakInactiveCardHeader: {
    backgroundColor: "#7dc5ff",
    color: "#ffffff",
  },
  fakCardSubheader: {
    color: "#ffffff",
  },
}));
