import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import config from "../Enviroment";

import {
  AppBar,
  createStyles,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import HomeIcon from "@material-ui/icons/Home";
import PagesIcon from "@material-ui/icons/Pages";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

type headerProps = {
  loggedIn: boolean;
  userName: string;
  role: string;
  logout: () => void;
};

function Logout(history: any, logout: () => void) {
  fetch("/api/users/logout.php", {
    method: "POST",
  }).then((response) => {
    logout();
    sessionStorage.clear();
    history.push("/");
  });
}

export default function ({ loggedIn, userName, role, logout }: headerProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const open = Boolean(anchorEl);

  const classes = useStyles();
  let history = useHistory();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h3" id="headerTitle" style={{ marginBottom: "8px", marginTop: "4px" }}>
              {config.header}
            </Typography>
            <Typography variant="subtitle2" id="headerSubtitle" style={{ marginBottom: "8px" }}>
              {config.moto}
              <br />
              <b>Zákaznická linka: </b> 545 230 805, <b>Email: </b> {config.mail}, Jsme tu pro Vás pondělí až pátek 7:30 –
              17:00, mimo svátky.
            </Typography>
          </div>
          {loggedIn ? (
            <>
              {role === "Admin" && (
                <IconButton onClick={() => history.push("/Home")} color="inherit">
                  <HomeIcon />
                </IconButton>
              )}
              <div>
                <IconButton onClick={handleMenu} color="inherit">
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      history.push("/EditProfile");
                    }}
                  >
                    Upravit profil
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      Logout(history, logout);
                    }}
                  >
                    Odhlásit se
                  </MenuItem>
                </Menu>
              </div>
              {role === "Admin" && (
                <IconButton onClick={() => setLeftMenuOpen(true)} color="inherit">
                  <SettingsIcon />
                </IconButton>
              )}
            </>
          ) : (
            <Typography
              variant="subtitle2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/Login");
              }}
            >
              Přihlásit se
            </Typography>
          )}
        </Toolbar>
        <LinearProgress variant="determinate" value={100} color="secondary" />
      </AppBar>
      <Drawer anchor={"right"} open={leftMenuOpen} onClose={() => setLeftMenuOpen(false)}>
        <List>
          <ListItem button onClick={() => history.push("/usersAdmin")}>
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Administrace uživatelů" />
          </ListItem>
          <ListItem button onClick={() => history.push("/priceLists")}>
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <ListItemText primary="Úprava ceníků" />
          </ListItem>
          <ListItem button onClick={() => history.push("/LabelGenerator")}>
            <ListItemIcon>
              <PagesIcon />
            </ListItemIcon>
            <ListItemText primary="Generátor etiket" />
          </ListItem>
          <ListItem button onClick={() => history.push("/Stats")}>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary="Statistiky" />
          </ListItem>
          <ListItem button onClick={() => history.push("/Administration")}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Administrace" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
