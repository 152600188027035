import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { DiscountCode } from "../../commonTypes";
import { Button, Grid, TextField, Checkbox, FormControlLabel } from "@material-ui/core";

interface IProps {
  discountCodes: DiscountCode[];
  setDiscountCodes: (newCodes: DiscountCode[]) => void;
}

export default function ({ discountCodes, setDiscountCodes }: IProps) {
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);

  const validateCode = () => {
    if (code === "" || discountCodes.filter((c) => c.Code === code).length > 0) {
      setCode("");
      return;
    }
    let formData = new FormData();
    formData.append("code", code);
    fetch(`/api/discountCodes/validate.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok)
          response.json().then((res) => {
            if (res === false) alert("Slevový kupón neexistuje nebo jeho platnost již vypršela");
            else {
              setDiscountCodes([...discountCodes, res]);
              setCode("");
            }
          });
      })
      .catch(console.log);
  };

  const removeCode = (code: DiscountCode) => {
    setDiscountCodes(discountCodes.filter((c) => c.Code !== code.Code));
  };

  return (
    <div style={{ marginLeft: "32px" }}>
      <FormControlLabel
        control={<Checkbox color="primary" checked={show} onClick={() => setShow(!show)} />}
        label="Aplikovat slevový kód"
      />
      {show && (
        <>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={4}>
              <TextField
                fullWidth
                type="text"
                placeholder="Slevový kód"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={8}>
              <Button variant="contained" color="primary" onClick={validateCode}>
                Ověřit a aplikovat
              </Button>
            </Grid>
          </Grid>
          {discountCodes.map((dc) => (
            <p key={dc.Code}>
              <FontAwesomeIcon onClick={() => removeCode(dc)} icon={faMinus} style={{ cursor: "pointer", color: "red" }} />{" "}
              {dc.Code}: {dc.Description}
            </p>
          ))}
        </>
      )}

      <br />
    </div>
  );
}
