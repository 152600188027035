import React, { useState, useEffect } from "react";
import { separateThousands, urlFormatParams } from "../../common";
import PriceListInfo from "./PriceListInfo";
import PriceTable, { calcTableStats } from "./PriceTable";
import GiftsContainer from "./Gifts";
import PremiumServicesContainer from "./PremiumServices";
import AddView from "../../Components/AddView";
import DiscountCodesContainer from "./DiscountCodesContainer";
import { DiscountCode, orderState, premiumService, priceList, priceTable } from "../../commonTypes";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import FAKsPreview from "./FAKsPreview";
import { localStorageKeys } from "../../storage";

const emptyPriceList: priceList = {
  name: "",
  id: -1,
  tables: [],
  gifts: [],
  premiumServices: [],
  settings: {
    validFrom: "",
    minAmmount: 0,
    minAmmountPersonal: 0,
    add: undefined,
    showCalendar: true,
    showMoreGoods: true,
    RefundList: false,
    DeliveryDays: {
      Mo: true,
      Tu: true,
      We: true,
      Th: true,
      Fr: true,
    },
    PreparedDeliveryDays: {
      Mo: false,
      Tu: false,
      We: false,
      Th: false,
      Fr: false,
    },
  },
};

export function calculateTotalPrice(tables: priceTable[], ammounts: ammountsType, discountCodes: DiscountCode[]) {
  let price = 0;
  let ammount = 0;
  let canBeOrdered = true;
  tables.forEach((t) => {
    const { totalAmmount, totalPrice, isAmmountOk } = calcTableStats(t, ammounts[t.id], discountCodes);
    price += totalPrice;
    ammount += totalAmmount;
    canBeOrdered = canBeOrdered && isAmmountOk;
  });
  price -= discountCodes
    .filter((dc) => dc.MinAmmount <= price)
    .map((dc) => dc.Discount)
    .reduce((x, acc) => x + acc, 0);
  return { price, ammount, canBeOrdered };
}

export function getPremiumServicePrice(ps: premiumService, orderPrice: number) {
  if (ps.freeFrom === 0) return ps.price;
  if (ps.freeFrom < orderPrice) return 0;
  return ps.price;
}

export type productAmmountsType = { [index: number]: number };

export type tableAmmountsType = { [index: number]: productAmmountsType };

export type ammountsType = { [index: number]: tableAmmountsType };

export default function ({ admin, history }: { admin: boolean; history: any }) {
  const [state, setState] = useState<orderState>({
    priceList: emptyPriceList,
    ammounts: {},
    selectedGiftId: -2,
    discountCodes: [],
    selectedServices: [],
  });
  const [allPriceLists, setAllPriceLists] = useState<priceList[]>([]);
  const [adminSelectedMail, setAdminSelectedMail] = useState("");
  const [selectedFak, setSelectedFak] = useState(-1);
  const { priceList, ammounts, selectedGiftId: selectedGiftIdState, discountCodes } = state;

  const changeAmmount = (tableId: number, productId: number, newVal: number) => {
    let newAmm = { ...ammounts };
    newAmm[tableId] = { ...ammounts[tableId] };
    newAmm[tableId][productId] = { ...newAmm[tableId][productId] };
    newAmm[tableId][productId][selectedFak] = newVal;
    setState({ ...state, ammounts: newAmm });
  };

  const setOrderProducts = (products: { sukl: string; ammount: number }[]) => {
    let newAmm = { ...ammounts };
    products.forEach(({ sukl, ammount }) => {
      if (sukl === undefined || sukl === null || sukl.length === 0) return;
      priceList.tables.forEach((table) => {
        newAmm[table.id] = { ...newAmm[table.id] };
        table.data.forEach((item) => {
          if (sukl === item.sukl) {
            newAmm[table.id][item.id] = { ...newAmm[table.id][item.id] };
            newAmm[table.id][item.id][selectedFak] = parseInt(ammount.toString());
          }
        });
      });
    });
    setState({ ...state, ammounts: newAmm });
  };

  const setReccomendedAmmounts = (table: priceTable) => {
    let newAmm = { ...ammounts };
    newAmm[table.id] = { ...ammounts[table.id] };
    table.data.forEach((d) => {
      newAmm[table.id][d.id] = { ...newAmm[table.id][d.id] };
      newAmm[table.id][d.id][selectedFak] =
        d.orderRecAmmount === undefined || d.orderRecAmmount ? parseInt(d.recomendedAmmount.toString()) : 0;
    });
    setState({ ...state, ammounts: newAmm });
  };

  const transferAmmounts = (table: number, from: number[], to: number[]) => {
    let newAmm = { ...ammounts };
    newAmm[table] = { ...ammounts[table] };
    from.forEach((val, i) => {
      newAmm[table][from[i]] = { ...newAmm[table][from[i]] };
      newAmm[table][to[i]] = { ...newAmm[table][to[i]] };
      newAmm[table][to[i]][selectedFak] = (newAmm[table][to[i]][selectedFak] || 0) + newAmm[table][from[i]][selectedFak];
      newAmm[table][from[i]][selectedFak] = 0;
    });
    setState({ ...state, ammounts: newAmm });
  };

  const selectGiftId = (id: number) => {
    if (selectedGiftId === id) {
      setState({ ...state, selectedGiftId: -1 });
    } else {
      setState({ ...state, selectedGiftId: id });
    }
  };

  const loadPriceList = (email?: string, priceList?: number) => {
    let data: { [index: string]: string } = {};
    if (email !== undefined) data.email = email;
    if (priceList !== undefined) data.id = priceList.toString();
    fetch("/api/priceLists/get.php" + urlFormatParams(data))
      .then((res) => res.json())
      .then((list) => {
        let newAmmounts: ammountsType = {};
        let tId = 0;
        (list as priceList).tables.forEach((t: priceTable) => {
          t.id = tId++;
          newAmmounts[t.id] = {};
          let dId = 0;
          t.data.forEach((d) => {
            d.id = dId++;
            newAmmounts[t.id][d.id] = {};
          });
        });
        if (!list.id || list.id < 0) {
          fetch("/api/settings/get.php?key=defaultPriceList")
            .then((res) => res.text())
            .then((id) => loadPriceList(undefined, parseInt(id)));
        } else {
          setState({
            priceList: list,
            ammounts: newAmmounts,
            selectedGiftId: -2,
            discountCodes: [],
            selectedServices: list.premiumServices
              .map((ps: premiumService, i: number) => {
                return { ...ps, index: i };
              })
              .filter((x: any) => x.obligatory)
              .map((x: any) => x.index),
          });
        }
      })
      .catch(() =>
        fetch("/api/settings/get.php?key=defaultPriceList")
          .then((res) => res.text())
          .then((id) => loadPriceList(undefined, parseInt(id)))
      );
  };

  const selectPriceList = (list: any) => {
    let formData = new FormData();
    formData.append("listId", list.toString());
    fetch(`/api/users/changePriceList.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    // if (admin) {
    fetch("/api/priceLists/getAll.php")
      .then((res) => res.json())
      .then((data) => {
        setAllPriceLists(data);
      })
      .catch(console.log);
    // }
    if (sessionStorage.getItem(localStorageKeys.OrderStateKey) !== null) {
      const cachedState = JSON.parse(sessionStorage.getItem(localStorageKeys.OrderStateKey) as string) as orderState;
      setState(cachedState);
      if (
        localStorage.getItem(localStorageKeys.userToLoadKey) !== undefined &&
        localStorage.getItem(localStorageKeys.userToLoadKey) !== null &&
        (localStorage.getItem(localStorageKeys.userToLoadKey) as string).length > 0
      ) {
        const mail = localStorage.getItem(localStorageKeys.userToLoadKey) as string;
        localStorage.setItem(localStorageKeys.userToLoadKey, "");
        setAdminSelectedMail(mail);
      }
    } else if (!admin) {
      loadPriceList();
    } else if (
      localStorage.getItem(localStorageKeys.userToLoadKey) !== undefined &&
      localStorage.getItem(localStorageKeys.userToLoadKey) !== null &&
      (localStorage.getItem(localStorageKeys.userToLoadKey) as string).length > 0
    ) {
      const mail = localStorage.getItem(localStorageKeys.userToLoadKey) as string;
      localStorage.setItem(localStorageKeys.userToLoadKey, "");
      setAdminSelectedMail(mail);
      loadPriceList(mail);
    }
    // eslint-disable-next-line
  }, [admin]);

  let { price, ammount, canBeOrdered } = calculateTotalPrice(priceList.tables, ammounts, discountCodes);
  price += state.selectedServices
    .map((i) => getPremiumServicePrice(priceList.premiumServices[i], price))
    .reduce((n, sum) => sum + n, 0);
  const getBestGiftId = () => {
    return state.priceList.gifts.filter((g) => g.minAmmount <= price).length - 1;
  };

  const selectedGiftId = selectedGiftIdState === -2 ? getBestGiftId() : selectedGiftIdState;
  let selectedPriceListTmp: any;
  return (
    <>
      {admin ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Vybrat ceník</InputLabel>
                <Select
                  onChange={(e) => {
                    loadPriceList(undefined, e.target.value as number);
                    setAdminSelectedMail("");
                  }}
                >
                  {allPriceLists.map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} md={5}>
              <TextField
                label="Vybrat ceník dle přiřazeného emailu"
                fullWidth
                onBlur={(e) => {
                  setAdminSelectedMail(e.target.value);
                  loadPriceList(e.target.value);
                }}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <Button variant="contained" color="primary">
                Ok
              </Button>
            </Grid>
          </Grid>
          <br />
          <br />
        </>
      ) : (
        ""
      )}
      {(!priceList.id || priceList.id < 0) && !admin && (
        // <Alert severity="warning" style={{ width: "80%", marginLeft: "10%" }}>
        //   Děkujeme Vám za registraci. Prosím, počkejte na potvrzení Vaší registrace. Tu zpravidla do 24 hodin překontrolujeme a
        //   přiřadíme Vaší škole Přehlednou nabídku produktů podle věku pro mateřské, základní a střední školy (každá individuální
        //   nabídka obsahuje tipy, rady a obvyklá upozornění od našich lékárníků vztahující se k věku pacientů). Po přiřazení
        //   Přehledné nabídky Vám na email přijde potvrzovací zpráva. Následně se budete moci přihlásit, spravovat a dovybavovat
        //   Vaše lékárničky.
        // </Alert>
        <Grid container spacing={3}>
          <Grid item md={8} xs={12}>
            <FormControl fullWidth>
              <InputLabel>Vybrate si prosím ceník s příslušnou nabídkou</InputLabel>
              <Select
                onChange={(e) => {
                  selectedPriceListTmp = e.target.value;
                }}
              >
                {allPriceLists.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "16px" }}
              onClick={() => selectPriceList(selectedPriceListTmp)}
            >
              Vybrat
            </Button>
          </Grid>
        </Grid>
      )}
      <PriceListInfo priceList={priceList} />
      <br />
      <br />
      {priceList.settings.add && <AddView src={`/img/${priceList.settings.add}`} addLink={priceList.settings.addLink} />}
      <FAKsPreview
        orderProducts={setOrderProducts}
        selectedFAK={selectedFak}
        selectFAK={setSelectedFak}
        admin={admin}
        actMail={adminSelectedMail}
      />
      <br />
      {priceList.tables.map((table) => (
        <PriceTable
          key={table.id}
          table={table}
          discountCodes={discountCodes}
          ammounts={ammounts[table.id]}
          inputClickHandler={changeAmmount}
          setReccomendedAmmounts={setReccomendedAmmounts}
          transferAmmount={transferAmmounts}
          selectedFak={selectedFak}
        />
      ))}

      {priceList.id >= 0 && priceList.settings.useDiscountCodes && (
        <DiscountCodesContainer
          discountCodes={discountCodes}
          setDiscountCodes={(codes) => setState({ ...state, discountCodes: codes })}
        />
      )}
      <br />
      {priceList.gifts.length > 0 && (
        <PremiumServicesContainer
          orderPirce={selectedGiftId === -1 ? price : price + priceList.gifts[selectedGiftId].price}
          services={priceList.premiumServices}
          selectedServices={state.selectedServices}
          selectService={(id, selected) => {
            if (selected) setState({ ...state, selectedServices: [...state.selectedServices, id] });
            else setState({ ...state, selectedServices: state.selectedServices.filter((x) => x !== id) });
          }}
        />
      )}
      <br />
      <GiftsContainer gifts={priceList.gifts} totalPrice={price} selectedGift={selectedGiftId} selectGift={selectGiftId} />
      <br />
      <br />
      <br />
      <br />

      {priceList.id >= 0 && (
        <div className="right text-right" style={{ width: "100%", marginRight: "32px" }}>
          <Typography variant="h5">
            Celková cena objednávky:{" "}
            {selectedGiftId === -1
              ? separateThousands(price.toString())
              : separateThousands((price + priceList.gifts[selectedGiftId].price).toString())}{" "}
            Kč
          </Typography>
          <Typography variant="h6">Celkový odběr: {ammount} ks</Typography>
          {price >= priceList.settings.minAmmount ? (
            <></>
          ) : (
            <Alert severity="warning" style={{ width: "80%", marginLeft: "10%" }}>
              Vaše objednávka je nižší, než je minimální výše objednávky. Doplňte, prosím, ještě Vaši objednávku. Děkujeme.
            </Alert>
          )}
          <br />
          <Button
            color={price >= priceList.settings.minAmmount ? "primary" : "secondary"}
            variant={price >= priceList.settings.minAmmount ? "contained" : "outlined"}
            disabled={!canBeOrdered || selectedFak === -1}
            onClick={() => {
              let strValue = { ...state, selectedGiftId, selectedMail: adminSelectedMail };
              sessionStorage.setItem(localStorageKeys.OrderStateKey, JSON.stringify(strValue));
              history.push("/OrderRecapitulation");
            }}
          >
            Rekapitulace objednávky
          </Button>
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
