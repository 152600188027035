import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ({ match, history }: { match: any; history: any }) {
  const [state, setState] = useState({ email: "", pwd: "", showPwd: false });
  const renewPwd = () => {
    if (state.email.length === 0 || state.pwd.length === 0) {
      alert("Vyplňte email a heslo");
      return;
    }
    let formData = new FormData();
    formData.append("token", match.params.securityToken);
    formData.append("email", state.email);
    formData.append("newPwd", state.pwd);
    fetch(`/api/users/renewPwd.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Vaše heslo bylo změněno");
          history.push("/Login");
        } else {
          alert("Při obnově heslo došlo k chybě. Pravděpodoně jste zadali špatný email nebo platnost odkazu již vypršela.");
        }
      })
      .catch(console.log);
  };

  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Obnova hesla
        </Typography>
        <form className={classes.form}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            onChange={(e) => {
              setState({ ...state, email: e.target.value });
            }}
            autoFocus
            value={state.email}
          />
          <FormControl fullWidth required>
            <InputLabel htmlFor="standard-adornment-password">Heslo</InputLabel>
            <Input
              type={state.showPwd ? "text" : "password"}
              value={state.pwd}
              onChange={(e) => {
                setState({ ...state, pwd: e.target.value });
              }}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setState({ ...state, showPwd: !state.showPwd })}>
                    {state.showPwd ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
              e.preventDefault();
              renewPwd();
            }}
          >
            Obnovit heslo
          </Button>
        </form>
      </div>
    </Container>
  );
}
