import { Typography } from "@material-ui/core";
import React from "react";

type footerType = {
  loggedIn: boolean;
  userName: string;
};

function Footer({ loggedIn, userName }: footerType) {
  return (
    <footer>
      {loggedIn && (
        <>
          <hr />
          <div id="ServiceInfo">
            <Typography variant="body2">
              Veškerý obsah, zejména struktura Aplikace včetně prvků, produkty a informace o nich včetně cen, akcí a
              individualizovaných podmínek, uvedený v Aplikaci podléhá obchodnímu tajemství. Veškeré další šíření obsahu v
              jakékoliv formě je výslovně zakázáno a v případě zjištění porušení bude toto důrazně vymáháno. Děkujeme za
              pochopení. Jste přihlášen jako: <b>{userName}</b>
            </Typography>
          </div>
        </>
      )}
    </footer>
  );
}

export default Footer;
