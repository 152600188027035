import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function () {
  const [showPwd, setShowPwd] = useState(false);
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");

  const resetForm = () => {
    setShowPwd(false);
    setShowNewPwd(false);
    setOldPwd("");
    setNewPwd("");
  };

  const classes = useStyles();

  const changePwd = () => {
    if (oldPwd.length === 0 || newPwd.length === 0) {
      alert("Zadejte hesla");
      return;
    }
    let formData = new FormData();
    formData.append("oldPwd", oldPwd);
    formData.append("newPwd", newPwd);
    fetch(`/api/users/changePwd.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Heslo bylo úspěšně změněno");
          resetForm();
        } else {
          alert("Špatné heslo");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <br />
      <br />
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Změna hesla
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="standard-adornment-password">Staré heslo</InputLabel>
        <Input
          type={showPwd ? "text" : "password"}
          value={oldPwd}
          onChange={(e) => {
            setOldPwd(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPwd(!showPwd)}>{showPwd ? <Visibility /> : <VisibilityOff />}</IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Nové heslo</InputLabel>
        <Input
          type={showNewPwd ? "text" : "password"}
          value={newPwd}
          onChange={(e) => {
            setNewPwd(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowNewPwd(!showNewPwd)}>
                {showNewPwd ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Button onClick={changePwd} fullWidth variant="contained" color="primary" className={classes.submit}>
        Změnit heslo
      </Button>
    </>
  );
}
