import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

export default function ({ onDelete, id }: { onDelete: () => void; id: number }) {
  const [show, setShow] = useState(false);
  const resetState = () => {
    setShow(false);
  };

  const deletePriceList = () => {
    let formData = new FormData();
    formData.append("id", id.toString());
    fetch(`/api/priceLists/delete.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onDelete();
          resetState();
        } else {
          console.log(response.status, response.statusText);
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer", color: "red" }} onClick={(e) => setShow(true)} />

      <Dialog open={show} onClose={resetState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>{"Opravdu chcete smazat aktuální ceník?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Ceník bude spolu se všemi daty trvale smazán.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetState} color="default">
            Zrušit
          </Button>
          <Button onClick={deletePriceList} style={{ color: "red" }} autoFocus>
            Smazat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
