import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";

export default function ({
  onCreate,
}: {
  onCreate: (premiumService: {
    name: string;
    subtitle: string;
    price: number;
    minPrice: number;
    freeFrom: number;
    obligatory: boolean;
  }) => void;
}) {
  const emptyInfo = { name: "", price: 0, minPrice: 0, subtitle: "", freeFrom: 0, obligatory: false };
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState(emptyInfo);
  const resetState = () => {
    setShow(false);
    setInfo(emptyInfo);
  };
  const createService = () => {
    if (info.name === "") {
      alert("Zadejte název služby");
      return;
    }
    onCreate(info);
    resetState();
  };

  return (
    <>
      <br />
      <Button variant="contained" color="primary" className="right" onClick={() => setShow(true)}>
        Přidat prémiovou službu
      </Button>

      <Dialog open={show} onClose={resetState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>Přidat prémiovou službu</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            autoFocus
            label="Název služby"
            value={info.name}
            onChange={(e) => setInfo({ ...info, name: e.target.value })}
          />
          <TextField
            fullWidth
            multiline
            label="Popis služby"
            value={info.subtitle}
            onChange={(e) => setInfo({ ...info, subtitle: e.target.value })}
          />
          <br />
          <br />
          <TextField
            fullWidth
            label="Cena služby"
            type="number"
            value={info.price}
            onChange={(e) => setInfo({ ...info, price: parseInt(e.target.value) })}
          />
          <TextField
            fullWidth
            label="Minimální cena objednávky"
            type="number"
            value={info.minPrice}
            onChange={(e) => setInfo({ ...info, minPrice: parseInt(e.target.value) })}
          />
          <TextField
            fullWidth
            label="Zdarma od"
            type="number"
            value={info.freeFrom}
            onChange={(e) => setInfo({ ...info, freeFrom: parseInt(e.target.value) })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={info.obligatory}
                onChange={(e) => setInfo({ ...info, obligatory: !info.obligatory })}
                color="primary"
              />
            }
            label="Povinná služba"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={resetState}>Zrušit</Button>
          <Button color="primary" onClick={createService}>
            Přidat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
