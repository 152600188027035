import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import { priceTable, product } from "../../commonTypes";
import { tableAmmountsType } from "./Order";
import { formatExpiration } from "./PriceTable";

interface IProps {
  table: priceTable;
  ammounts: tableAmmountsType;
  transferAmmount: (tableId: number, productFrom: number[], productTo: number[]) => void;
  selectedFak: number;
}

type alternative = { product: product; alternative: product; ammount: number };

const findProductBySukl = (products: product[], sukl: string) => {
  return products.filter((p) => p.sukl === sukl)[0];
};

const getIdBySukl = (products: product[], sukl: string) => {
  return products
    .map((p, i) => {
      return { sukl: p.sukl, id: i };
    })
    .filter((p) => p.sukl === sukl)[0].id;
};

const getAlternatives = (ammounts: tableAmmountsType, selectedFak: number, products: product[]) => {
  const res: alternative[] = [];
  ((Object.keys(ammounts) as unknown[]) as number[]).forEach((prodIndex: number) => {
    if (
      ammounts[prodIndex][selectedFak] > 0 &&
      products[prodIndex].alternative !== undefined &&
      products[prodIndex].alternative.length > 0
    ) {
      const alt = findProductBySukl(products, products[prodIndex].alternative);
      if (alt.available) {
        res.push({
          product: products[prodIndex],
          alternative: alt,
          ammount: ammounts[prodIndex][selectedFak],
        });
      }
    }
  });
  return res;
};

const getTotalSaved = (alts: alternative[]) => {
  return alts.map((alt) => alt.ammount * (alt.product.price - alt.alternative.price)).reduce((a, b) => a + b, 0);
};

export default function ({ table, ammounts, selectedFak, transferAmmount }: IProps) {
  const [open, setOpen] = useState(false);
  const alternatives = getAlternatives(ammounts, selectedFak, table.data);

  return (
    <>
      {alternatives.length > 0 && (
        <>
          <Button color="secondary" variant="contained" style={{ marginLeft: "32px" }} onClick={() => setOpen(true)}>
            Najděte levnější alternativy s funkcí Stejná účinná látka levnější výrobce. Ušetříte až{" "}
            {getTotalSaved(alternatives)} Kč nebo získáte větší výhodnější alternativu.
          </Button>
        </>
      )}
      <Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Stejná účinná látka, levnější výrobce</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Levnější alternativy, které je možné nahradit
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Objednaný produkt</TableCell>
                  <TableCell>Levnější alternativa</TableCell>
                  <TableCell>Poznámka k alternativě</TableCell>
                  <TableCell>Objednané množství</TableCell>
                  <TableCell>Ušetříte za kus</TableCell>
                  <TableCell>Ušetříte celkem</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alternatives.map((alt) => (
                  <TableRow key={alt.product.sukl}>
                    <TableCell>
                      {alt.product.name} ({alt.product.price} Kč), <b>EXP {formatExpiration(alt.product.expiration)}</b>
                    </TableCell>
                    <TableCell>
                      {alt.alternative.name} ({alt.alternative.price} Kč),{" "}
                      <b>EXP {formatExpiration(alt.alternative.expiration)}</b>
                    </TableCell>
                    <TableCell>{alt.alternative.remark}</TableCell>
                    <TableCell>{alt.ammount}</TableCell>
                    <TableCell>{alt.product.price - alt.alternative.price} Kč</TableCell>
                    <TableCell>{alt.ammount * (alt.product.price - alt.alternative.price)} Kč</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        onClick={() =>
                          transferAmmount(
                            table.id,
                            [getIdBySukl(table.data, alt.product.sukl)],
                            [getIdBySukl(table.data, alt.alternative.sukl)]
                          )
                        }
                      >
                        Nahradit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              const from: number[] = [];
              const to: number[] = [];
              alternatives.forEach((alt) => {
                from.push(getIdBySukl(table.data, alt.product.sukl));
                to.push(getIdBySukl(table.data, alt.alternative.sukl));
              });
              transferAmmount(table.id, from, to);
              setOpen(false);
            }}
            color="primary"
          >
            Nahradit vše
          </Button>
          <Button onClick={() => setOpen(false)} color="secondary">
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
