import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { separateThousands } from "../../common";
import AddView from "../../Components/AddView";
import { ammountsType, tableAmmountsType, productAmmountsType } from "./Order";
import { DiscountCode, priceTable, product } from "../../commonTypes";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { commonClasses } from "../../styles";
import { constants } from "../../consts";
import { getEditorCols } from "../EditPriceList/TableEditor";
import SameDrugBetterPrice from "./SameDrugBetterPrice";

const priceTableClasses = makeStyles((theme) => ({
  unavailableRow: {
    backgroundColor: "#bfbfbf",
  },
  actPriceContainer: {
    backgroundColor: "rgba(33, 150, 243, 0.6)",
  },
}));

export function getProductPrice(
  product: product,
  ammounts: ammountsType,
  table: priceTable,
  discountCodes: DiscountCode[],
  selectedFak: number
) {
  const mixDiscontsFrom = [...table.format.mixDiscounts, 99999999999999999];
  let ammount = ammounts[table.id][product.id][selectedFak];
  let totalAmmount = Object.keys(ammounts[table.id]).reduce(
    (sum, act) => sum + productTotalAmmount(ammounts[table.id][parseInt(act)]),
    0
  );
  return calcCurrentPrice(product, ammount, totalAmmount, mixDiscontsFrom, discountCodes).singlePrice;
}

function getCurrentPrice(row: product, ammount: number, mixDiscountsFrom: number[]) {
  if (ammount < mixDiscountsFrom[0]) return [row.price, -1];
  else {
    let ind = 0;
    while (ammount >= mixDiscountsFrom[ind + 1]) ind++;
    return [row.mixDiscounts[ind], ind];
  }
}

function getAmmountDiscount(row: product, ammount: number) {
  let resDisc = 0;
  let highlightedRes: number[] = [];

  row.ammountDiscountsFrom.forEach((val, ind) => {
    if (val > 0 && val <= ammount) {
      resDisc += row.ammountDiscounts[ind];
      highlightedRes.push(ind);
    }
  });
  return { resDisc, highlightedRes };
}

export function formatExpiration(exp: string) {
  if (exp === undefined || exp === null || exp.length === 0) return <></>;
  const [year, month] = exp.split("-");
  let expDate = new Date(parseInt(year), parseInt(month), 1);
  expDate.setMonth(expDate.getMonth() - 12);
  const res = `${month}/${year.slice(-2)}`;
  if (expDate.getTime() < new Date().getTime()) return <b>{res}</b>;
  return res;
}

export function productTotalAmmount(ammounts: productAmmountsType) {
  return Object.keys(ammounts).reduce((sum, key) => sum + ammounts[parseInt(key)], 0) || 0;
}
export function calcTableStats(
  table: priceTable,
  ammounts: tableAmmountsType,
  discountCodes: DiscountCode[],
  selectedFak?: number
) {
  const totalAmmount = Object.keys(ammounts).reduce((sum, key) => sum + productTotalAmmount(ammounts[parseInt(key)]), 0);
  const mixDiscontsFrom = [...table.format.mixDiscounts, 99999999999999999];
  const totalPrice =
    Math.round(
      100 *
        table.data
          .filter((d) => d.available)
          .map(
            (d) => calcCurrentPrice(d, productTotalAmmount(ammounts[d.id]), totalAmmount, mixDiscontsFrom, discountCodes).price
          )
          .reduce((sum, act) => sum + act, 0)
    ) / 100;
  return {
    totalAmmount,
    totalPrice,
    mixDiscontsFrom,
    isAmmountOk: totalAmmount === 0 || totalAmmount >= table.settings.minAmmount,
  };
}

function calcCurrentPrice(
  row: product,
  ammount: number,
  totalAmmount: number,
  mixDiscontsFrom: number[],
  discountCodes: DiscountCode[]
) {
  let [actPrice, mixDiscCol] = getCurrentPrice(row, totalAmmount, mixDiscontsFrom);
  const { resDisc: discount, highlightedRes: highlighedtDiscounts } = getAmmountDiscount(row, ammount);
  if (row.sukl) {
    actPrice -= discountCodes
      .filter((d) => d.Sukl === row.sukl && ammount >= d.MinAmmount)
      .map((d) => d.Discount)
      .reduce((sum, act) => sum + act, 0);
  }
  actPrice -= discount;
  return {
    price: Math.round(100 * ammount * actPrice) / 100,
    mixDiscountCol: mixDiscCol,
    highlighedtDiscounts: highlighedtDiscounts,
    singlePrice: actPrice,
    discountCodeUsed: row.sukl && discountCodes.filter((d) => d.Sukl === row.sukl && ammount >= d.MinAmmount).length > 0,
  };
}

interface IProps {
  table: priceTable;
  ammounts: tableAmmountsType;
  inputClickHandler: (tableId: number, productId: number, newAmmount: number) => void;
  transferAmmount: (tableId: number, productFrom: number[], productTo: number[]) => void;
  setReccomendedAmmounts: (table: priceTable) => void;
  discountCodes: DiscountCode[];
  selectedFak: number;
}

export default function PriceTable({
  table,
  ammounts,
  inputClickHandler,
  setReccomendedAmmounts,
  transferAmmount,
  discountCodes,
  selectedFak,
}: IProps) {
  const styles = commonClasses();
  const priceTableStyles = priceTableClasses();
  const [showTable, setShowTable] = useState(!table.settings.hide);
  const { totalAmmount, totalPrice, mixDiscontsFrom, isAmmountOk } = calcTableStats(
    table,
    ammounts,
    discountCodes,
    selectedFak
  );
  const res = (
    <>
      {table.settings.add && <AddView src={`/img/${table.settings.add}`} addLink={table.settings.addLink} />}
      <Card className={styles.fakCard}>
        <CardHeader
          className={styles.fakCardHeader}
          title={
            <>
              {table.name} {table.settings.minAmmount > 0 ? `( minimální odběr: ${table.settings.minAmmount} ks)` : ""}
              <FontAwesomeIcon
                className="right pointer"
                icon={showTable ? faEyeSlash : faEye}
                onClick={() => setShowTable(!showTable)}
              />
            </>
          }
        />

        {showTable && (
          <CardContent style={{ overflowX: "auto" }}>
            {table.format.recommendedAmmount && (
              <Button style={{ marginLeft: "32px" }} onClick={(e) => setReccomendedAmmounts(table)} color="primary">
                Objednat doporučené množství
              </Button>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  {table.format.sukl && <TableCell>Súkl</TableCell>}
                  <TableCell>Název produktu</TableCell>
                  <TableCell>Expirace</TableCell>
                  {table.format.basicPrice && <TableCell>Cena</TableCell>}
                  {table.format.mixDiscounts.map((d, i) => (
                    <TableCell key={i}>Cena Mix {d}+</TableCell>
                  ))}
                  {table.format.ammountDiscountsCount > 0 && (
                    <TableCell colSpan={table.format.ammountDiscountsCount}>Množstevní sleva</TableCell>
                  )}
                  {table.format.commonPrice && <TableCell>Běžná cena</TableCell>}
                  {table.format.suklPrice && <TableCell>Cena dle Súkl</TableCell>}
                  {table.format.recommendedAmmount && <TableCell>Doporučené množství</TableCell>}
                  <TableCell>Poznámka</TableCell>
                  <TableCell>Cena v objednávce</TableCell>
                  <TableCell>Množství</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table.data.map((d) => {
                  const { price, mixDiscountCol, highlighedtDiscounts, discountCodeUsed } = calcCurrentPrice(
                    d,
                    ammounts[d.id][selectedFak] || 0,
                    totalAmmount,
                    mixDiscontsFrom,
                    discountCodes
                  );

                  return d.sukl === constants.separator ? (
                    <TableRow key={d.id}>
                      <TableCell style={{ fontWeight: "bold", fontSize: "1rem" }} colSpan={getEditorCols(table.format) + 1}>
                        {d.name}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={d.id}
                      className={!d.available ? priceTableStyles.unavailableRow : ""}
                      style={{
                        backgroundColor: d.highlighted ? d.highlightColor || "rgba(255, 167, 202, 0.932)" : "inherit",
                      }}
                    >
                      {table.format.sukl && <TableCell>{d.sukl}</TableCell>}
                      <TableCell>{d.name}</TableCell>
                      <TableCell>{formatExpiration(d.expiration)}</TableCell>
                      {table.format.basicPrice && <TableCell>{separateThousands(d.price.toString())} Kč</TableCell>}
                      {d.mixDiscounts.length > 0 &&
                        d.mixDiscounts.map((disc, i) => {
                          return (
                            <TableCell key={i} className={mixDiscountCol === i ? priceTableStyles.actPriceContainer : ""}>
                              {disc !== null ? `${separateThousands(disc.toString())} Kč` : ""}
                            </TableCell>
                          );
                        })}
                      {Array.from(Array(table.format.ammountDiscountsCount).keys()).map((ind) => (
                        <TableCell className={highlighedtDiscounts.includes(ind) ? priceTableStyles.actPriceContainer : ""}>
                          {d.ammountDiscounts.length > ind &&
                            d.ammountDiscounts[ind] !== 0 &&
                            `${d.ammountDiscounts[ind]} Kč od ${d.ammountDiscountsFrom[ind]} Ks`}
                        </TableCell>
                      ))}
                      {table.format.commonPrice && <TableCell>{d.commonPrice}</TableCell>}
                      {table.format.suklPrice && <TableCell>{d.suklPrice}</TableCell>}
                      {table.format.recommendedAmmount && <TableCell>{d.recomendedAmmount} ks</TableCell>}
                      <TableCell>{d.remark}</TableCell>
                      {!d.available ? (
                        <TableCell colSpan={3}>Momentálně nedostupné</TableCell>
                      ) : (
                        <>
                          <TableCell style={discountCodeUsed ? { color: "green" } : {}}>{price} Kč</TableCell>
                          <TableCell>
                            <input
                              type="number"
                              className="orderFormControll"
                              value={ammounts[d.id][selectedFak] || 0}
                              min={0}
                              onChange={(e) => inputClickHandler(table.id, d.id, parseInt(e.target.value))}
                            />
                          </TableCell>
                          <TableCell>
                            {(ammounts[d.id][selectedFak] || 0) < productTotalAmmount(ammounts[d.id]) && (
                              <span style={{ color: "grey" }}>
                                +{productTotalAmmount(ammounts[d.id]) - (ammounts[d.id][selectedFak] || 0)}
                              </span>
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        )}
      </Card>
      <br />
      {table.format.recommendedAmmount && (
        <Button
          style={{ marginLeft: "32px" }}
          variant="contained"
          onClick={(e) => setReccomendedAmmounts(table)}
          color="primary"
        >
          Objednat doporučené množství
        </Button>
      )}
      <SameDrugBetterPrice table={table} transferAmmount={transferAmmount} ammounts={ammounts} selectedFak={selectedFak} />
      <Typography variant="body1" className="right" style={{ marginRight: "32px", marginTop: "16px" }}>
        <b>Celková cena za tabulku: {separateThousands(totalPrice.toString())} Kč</b>
        <br />
        <span className={isAmmountOk ? "" : "text-danger"}>Celkový odběr za tabulku: {totalAmmount} ks</span>
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
  return res;
}
