import { Avatar, Button, Container, CssBaseline, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";
import PwdRenewal from "./RenewPassword";
import AddView from "../../Components/AddView";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Alert } from "@material-ui/lab";

function LoginUser(email: string, pwd: string, history: any, checkLogin: () => void) {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("pwd", pwd);
  fetch(`/api/users/login.php`, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        checkLogin();
        history.push("/Home");
      } else {
        alert("Přihlášení se nezdařilo. Zkontolujte své uživatelské jméno a heslo.");
      }
    })
    .catch(console.log);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ({ history, checkLogin }: { history: any; checkLogin: () => void }) {
  const classes = useStyles();
  let email = "";
  let pwd = "";
  return (
    <>
      <Grid container direction="row-reverse">
        <Grid item xs={12} lg={4}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Přihlásit se
              </Typography>
              <form className={classes.form}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => {
                    email = e.target.value;
                  }}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Heslo"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    pwd = e.target.value;
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => {
                    e.preventDefault();
                    LoginUser(email, pwd, history, checkLogin);
                  }}
                >
                  Přihlásit se
                </Button>
                <Button fullWidth variant="contained" color="secondary" onClick={(e) => history.push("/Register")}>
                  Nemáte účet? Registrujte se zde
                </Button>
                <br />
                <br />

                {/* <Grid container>
                  <Grid item xs> */}
                <PwdRenewal />
                {/* </Grid>
                  <Grid item></Grid>
                </Grid> */}
              </form>
            </div>
          </Container>
        </Grid>
        <Grid item xs={12} lg={8}>
          <br />
          <br />
          <Alert severity="warning" style={{ width: "80%", marginLeft: "10%" }}>
            Nabídka FKSP - vitamínů pro školy není v aplikaci uvedena. Pro zaslání aktuální nabídky a více informací nás, prosím, kontaktujte na
            lekarna@lekarnickyproskoly.cz. Online aplikace Lékárničky pro školy slouží k doplnění a kontrole expirací léčiv a zdravotnického materiálu ve
            školních lékárničkách.
          </Alert>
          <br />
          <AddView src="/img/index.png" />
        </Grid>
      </Grid>
    </>
  );
}
