import React, { useState } from "react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import processImportData from "./importLib";
import { getEmptyDataRow } from "./TableEditor";
import { priceTableFormat } from "../../commonTypes";
import { resultType } from "./importLib";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";

function getColumnsNames(format: priceTableFormat) {
  let res = [];
  if (format.sukl) res.push({ name: "SÚKL sloupec", prop: "sukl" });
  res.push({ name: "Název sloupec", prop: "name" });
  res.push({ name: "Expirace sloupec", prop: "expiration" });
  if (format.basicPrice) res.push({ name: "Základní cena sloupec", prop: "price" });
  if (format.recommendedAmmount) res.push({ name: "Doporučené množství sloupec", prop: "recomendedAmmount" });
  format.mixDiscounts.map((d, i) => res.push({ name: `Cena MIX ${d}+ sloupec`, prop: "mixDiscounts" + i }));
  if (format.ammountDiscountsCount > 0) res.push({ name: "Množstevní sleva od sloupec", prop: "ammountDiscountFrom" });
  if (format.ammountDiscountsCount > 0) res.push({ name: "Množstevní sleva sloupec", prop: "ammountDiscount" });
  if (format.commonPrice) res.push({ name: "Běžná cena sloupec", prop: "commonPrice" });
  if (format.suklPrice) res.push({ name: "Cena dle SÚKL sloupec", prop: "suklPrice" });
  res.push({ name: "Poznámka sloupec", prop: "remark" });
  return res;
}

function getColumnsDefaultState(format: priceTableFormat) {
  let res: { [index: string]: number } = {};
  let i = 1;
  if (format.sukl) res["sukl"] = i++;
  res["name"] = i++;
  res["expiration"] = i++;
  if (format.basicPrice) res["price"] = i++;
  if (format.recommendedAmmount) res["recomendedAmmount"] = i++;
  format.mixDiscounts.map((d, ind) => (res["mixDiscounts" + ind] = i++));
  if (format.ammountDiscountsCount) res["ammountDiscountFrom"] = i++;
  if (format.ammountDiscountsCount) res["ammountDiscount"] = i++;
  if (format.commonPrice) res["commonPrice"] = i++;
  if (format.suklPrice) res["suklPrice"] = i++;
  res["remark"] = i++;
  return res;
}

export default function ({ onImport, tableFormat }: { onImport: (data: resultType[]) => void; tableFormat: priceTableFormat }) {
  const [show, setShow] = useState(false);
  const [columns, setColumns] = useState(getColumnsDefaultState(tableFormat));
  const [file, setFile] = useState<File | undefined>(undefined);
  const columnsDef = getColumnsNames(tableFormat);

  const resetState = () => {
    setShow(false);
    setColumns(getColumnsDefaultState(tableFormat));
    setFile(undefined);
  };

  const importData = () => {
    if (file === undefined) {
      alert("Vyberte soubor");
      return;
    }
    if (
      Object.values(columns).length !==
      Array.from(
        new Set(
          Object.values(columns)
            .map((v, i) => (v === 0 ? v + i * 1000 : v))
            .filter((x) => x >= 0)
        )
      ).length
    ) {
      alert("Vyplňte všechny sloupce různými hodnotami. Pokud sloupec nechcete importovat, nechte jej prázdný");
    }
    let fileReader = new FileReader();
    const emptyRow = getEmptyDataRow(tableFormat) as { [index: string]: any };
    fileReader.onloadend = (e) => {
      let data = processImportData((e.target as FileReader).result as string, columns);
      data = data.map((d) => {
        return { ...emptyRow, ...d };
      });
      onImport(data);
      resetState();
    };
    fileReader.readAsText(file);
  };

  return (
    <>
      <FontAwesomeIcon icon={faUpload} className="text-primary pointer" onClick={() => setShow(true)} />

      <Dialog open={show} onClose={resetState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>Importovat data</DialogTitle>
        <DialogContent>
          <DropzoneArea
            filesLimit={1}
            acceptedFiles={[
              ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
            ]}
            onChange={(files) => setFile(files.length > 0 ? files[0] : undefined)}
          />
          <Grid container spacing={3}>
            {columnsDef.map((col) => (
              <Grid item md={6} xs={12} key={col.prop}>
                <TextField
                  type="number"
                  label={col.name}
                  value={columns[col.prop]}
                  onChange={(e) => {
                    let newCols = { ...columns };
                    newCols[col.prop] = parseInt(e.target.value);
                    setColumns(newCols);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button color="primary" onClick={importData}>
            Importovat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
