export default process.env.REACT_APP_STAGE === "companies"
  ? {
      header: "Aplikace Lékárničky pro firmy",
      moto:
        "Inovativní Aplikace Lékárničky pro firmy Vám usnadní a zpříjemní dovybavení lékárniček, pohlídá EXP a zajistí recyklaci léků.",
      mail: "lekarna@lekarnickyprofirmy.cz",
      institution: "firmy",
      appName: "LekarnickyProFirmy.cz",
    }
  : {
      header: "Aplikace Lékárničky pro školy",
      moto:
        "Inovativní Aplikace Lékárničky pro školy Vám usnadní a zpříjemní dovybavení lékárniček, pohlídá EXP a zajistí recyklaci léků.",
      mail: "lekarna@lekarnickyproskoly.cz",
      institution: "školy",
      appName: "LekarnickyProSkoly.cz",
    };
