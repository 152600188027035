import { Button, Card, CardActions, CardContent, CardHeader, Modal, TextField, Link, Fade } from "@material-ui/core";
import React, { useState } from "react";
import { commonClasses } from "../../styles";

export default function () {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const resetState = () => {
    setShow(false);
    setEmail("");
  };

  const sendEmail = () => {
    if (email.length === 0) {
      alert("Vyplňte email");
      return;
    }
    let formData = new FormData();
    formData.append("email", email);
    fetch(`/api/mails/generatePasswordRenewEmail.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Byl Vám odeslán email s odkazem pro obnovu hesla");
          resetState();
        } else {
          alert("Při odesílání emailu pro obnovu heslo došlo k chybě. Zkuste to prosím později.");
          resetState();
        }
      })
      .catch(console.log);
  };

  const styles = commonClasses();

  return (
    <>
      <Link href="#" onClick={() => setShow(true)} variant="body2">
        Zapomněli jste heslo?
      </Link>

      <Modal open={show} onClose={() => setShow(false)} style={{ width: "40%", left: "30%", top: "20%" }} closeAfterTransition>
        <Fade in={show}>
          <Card className={styles.fakCard}>
            <CardHeader className={styles.fakCardHeader} title="Obnova hesla"></CardHeader>
            <CardContent>
              <TextField type="text" fullWidth placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </CardContent>
            <CardActions>
              <Button color="default" variant="contained" onClick={resetState}>
                Zrušit
              </Button>
              <Button color="primary" variant="contained" onClick={sendEmail}>
                Obnovit heslo
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
