import { /* Card, CardHeader, Grid,*/ Typography } from "@material-ui/core";
import React from "react";
// import { separateThousands } from "../../common";
// eslint-disable-next-line
import { /* DeliveryDays,*/ priceList } from "../../commonTypes";
// import { commonClasses } from "../../styles";

// function getDeliveryDaysString(days: DeliveryDays) {
//   let res = [];
//   if (days.Mo) res.push("Po");
//   if (days.Tu) res.push("Út");
//   if (days.We) res.push("St");
//   if (days.Th) res.push("Čt");
//   if (days.Fr) res.push("Pá");
//   return res.join(", ");
// }

function formatValidDate(date: string) {
  const spl = date.split("-");
  return `${spl[2]}-${spl[1]}-${spl[0]}`;
}

export default function PriceListInfo({ priceList }: { priceList: priceList }) {
  // const styles = commonClasses();
  return (
    <>
      {priceList.id >= 0 && (
        <>
          <Typography variant="h4" style={{ marginTop: "8px", marginBottom: "16px" }}>
            {priceList.name} (platnost od {formatValidDate(priceList.settings.validFrom)})
          </Typography>
          {/* <Grid container spacing={3}>
            {priceList.settings.minAmmount > 0 && (
              <Grid item xs={12} md={4}>
                <Card>
                  <CardHeader
                    className={styles.fakCardHeader}
                    classes={{ subheader: styles.fakCardSubheader }}
                    title={`Minimální odběr: ${separateThousands(priceList.settings.minAmmount.toString())} Kč`}
                    subheader={
                      priceList.settings.minAmmountPersonal >= 0 ? (
                        <>Při osobním vyzvednutí: {separateThousands(priceList.settings.minAmmountPersonal.toString())} Kč</>
                      ) : (
                        <br />
                      )
                    }
                  />
                </Card>
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              {getDeliveryDaysString(priceList.settings.DeliveryDays).length > 0 && (
                <Card>
                  <CardHeader
                    className={styles.fakCardHeader}
                    classes={{ subheader: styles.fakCardSubheader }}
                    title="Rozvozové dny"
                    subheader={getDeliveryDaysString(priceList.settings.DeliveryDays)}
                  />
                </Card>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {getDeliveryDaysString(priceList.settings.PreparedDeliveryDays).length > 0 && (
                <Card>
                  <CardHeader
                    className={styles.fakCardHeader}
                    classes={{ subheader: styles.fakCardSubheader }}
                    title="Připravované rozvozové dny"
                    subheader={getDeliveryDaysString(priceList.settings.PreparedDeliveryDays)}
                  />
                </Card>
              )}
            </Grid>
          </Grid> */}
        </>
      )}
    </>
  );
}
