import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";

export default function ({
  onDelete,
  onAddToCard,
  alreadyExpired,
}: {
  onDelete: () => void;
  onAddToCard: () => void;
  alreadyExpired: boolean;
}) {
  const [show, setShow] = useState(false);
  const resetState = () => {
    setShow(false);
  };

  return (
    <>
      {alreadyExpired ? (
        <Tooltip title="Tento produkt již téměř expiroval. Kliknutím vyřadíte z lékárničky.">
          <ErrorIcon onClick={(e) => setShow(true)} style={{ color: "red", cursor: "pointer" }} />
        </Tooltip>
      ) : (
        <Tooltip title="Tento produkt expiruje již do 2 měsíců. Kliknutím vyřadíte z lékárničky.">
          <WarningIcon onClick={(e) => setShow(true)} style={{ color: "#ff9800", cursor: "pointer" }} />
        </Tooltip>
      )}

      <Dialog open={show} onClose={resetState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>Chcete produkt opravdu vyřadit z evidence Aplikace?</DialogTitle>
        <DialogContent>
          <DialogContentText align="justify">
            V Aplikaci si můžete doobjednat přípravek znovu. Prosím, odeberte produkt i fyzicky z Vaší školní lékárničky.
            V lékárničce si můžete pomocí tlačítka STÁHNOUT PRO TISK stáhnout a následně vytisknout aktuální seznam obsahu
            lékárničky.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetState} color="default">
            Zrušit
          </Button>
          <Button
            onClick={() => {
              onDelete();
              resetState();
            }}
            color="secondary"
          >
            Vyřadit
          </Button>
          <Button
            onClick={() => {
              onAddToCard();
              onDelete();
              resetState();
            }}
            color="primary"
          >
            Vyřadit a přidat do košíku
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
